<template>
    <section class="reviews" id="custom">
        <div class="container">
            <h1 class="title">Отзывы наших клиентов</h1>
            <div class="reviews-wrap" v-if="reviews?.length">
                <Splide :options="mainOptions">
                    <SplideSlide v-for="(review, index) in reviews" :key="index" class="p-2">
                        <div class="review-wrap">
                            <div class="review-item">
                                <img :src="review.review_images[0].image" class="bg-left">
                                <span class="review-author">{{ review.full_name }}</span>
                                <p class="review-text">{{ review.comment }}</p>
                                <b class="review-date">{{ review.created_at }}</b>
                                <div class="review-rating">
                                    <i class="review-star" v-for="(star, i) in review.star" :key="i" :class="'star-' + star"></i>
                                </div>
                            </div>
                            <div class="review-item">
                                <img :src="review.review_images[1].image" class="bg-right">
                            </div>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>
        </div>
    </section>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'

export default {
    data() {
        return {
            mainOptions: {
                perPage: 1,
                classes: {
                    prev: 'custom-class-prev',
                    next: 'custom-class-next',
                },
                pagination: false
            }
        }
    },
    components: {
        Splide,
        SplideSlide
    },
    computed: {
        reviews() {
            return (this.$store.getters.getReviews)
            ? this.$store.getters.getReviews
            : this.$store.dispatch('getReviews')
        },
    }
}
</script>