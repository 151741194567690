<template>
  <div class="my-confirm-modal">
    <div class="my-login-modal-content">
      <p>
        <span>
          <b>Лот: </b>
          {{ data.lot }}
        </span>
        <span>
          <b>марка: </b>
          {{ data.brand }}
        </span>
      </p>
      <p>
        <span>
          <b>год: </b>
          {{ data.year }}
        </span>
        <span>
          <b>модель: </b>
          {{ data.model }}
        </span> 
      </p>
      <p>
        <span>
          <b>vin: </b>
          {{ data.vin }}
        </span>
        <span>
          <b>ваша ставка: </b>
          ${{ data.bid }}
        </span>
      </p>
      <button class="custom-button blink-animate block mt-4" @click="confirm">
        подтвердить
      </button>
      <div class="row d-flex justify-content-center close-btn">
        <button type="button" class="personal-btn" @click="close">
          Отмена
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["close", "data", "car_bid"],
  methods: {
    confirm() {
      this.car_bid();
    },
  },
};
</script>

<style scoped>
.my-login-modal-content {
  padding: 50px 60px 40px;
}

.my-login-modal-content p {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 185%;
}

.my-login-modal-content p span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.my-login-modal-content p b {
  text-transform: uppercase;
}

.close-btn {
  width: 240px;
  margin: 15px auto 0;
}

.close-btn button {
  background: #7b7979;
  border-radius: 3px;
}
</style>
