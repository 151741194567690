<template>
    <Header class="light"></Header>
    <SearchBrand></SearchBrand>
    <section class="brands">
        <div class="container">
            <div class="box-wrap">
                <h3 class="wrap-title text-center mb-4">Популярные марки</h3>
                <div class="row pt-3">
                    <template v-for="(mark, index)  in marks" :key="mark">
                        <div class="col-md-3" v-if="index <= 38 || fullList">
                            <router-link :to="'/auction?brand=' + mark.title" class="mark-list-item"><b>{{ String(mark.title).toLowerCase() }}</b><span>({{ mark.count }})</span></router-link>
                        </div>
                    </template>
                  <div class="col-md-3" v-on:click="showMore">
                    <div class="search-brand-text search-mark-text" v-if="fullList">Скрыть</div>
                    <div class="search-brand-text search-mark-text" v-else>Еще</div>
                  </div>
                </div>
            </div>
        </div>
    </section>
    <Contacts></Contacts>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import SearchBrand from '../components/SearchBrand.vue'
import Contacts from '@/components/Contacts.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
            fullList: false,
        }
    },
    components: {
        Header,
        SearchBrand,
        Contacts,
        Footer
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        marks() {
            return this.$store.getters.getBrandImage
        }
    },
    methods: {
      showMore() {
          this.fullList = !this.fullList
      }
    },
}
</script>

<style>
  .search-mark-text {
    cursor: pointer;
    padding: 0!important;
    height: auto!important;
  }
</style>