<template>
  <div class="container">
    <div class="all-cards">
      <div>
        <div>
          <p>Пополнить через платежную карту</p>
        </div>
        <div class="card-images">
          <img src="../assets/img/icons/visa.png" @click="click(8)" alt="visa">
          <img src="../assets/img/icons/mastercard.png" @click="click(8)" alt="card">
        </div>
      </div>

      <div>
        <div>
          <p>Пополнить через электронный кошелек</p>
        </div>
        <div class="card-images">
          <img src="../assets/img/icons/megapay.png" @click="click(8)" alt="megapay">
          <img src="../assets/img/icons/mbank.png" @click="click(8)" alt="mbank">
          <img src="../assets/img/icons/optima.png" @click="click(8)" alt="optima">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export  default {
    props: ['click']
  }
</script>


<style scoped>
  .all-cards {
    display: flex;
    justify-content: space-between;
    padding: 0 10%;
    margin-bottom: 210px;
  }

  .all-cards p {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }

  .card-images {
    display: flex;
    gap: 10%;
  }

  .card-images img {
    cursor: pointer;
    object-fit: contain;
  }

  @media screen and (max-width: 1280px){
    .all-cards p {
      font-size: 16px;
    }

    .card-images img {
      width: 80px;
    }
  }

  @media screen and (max-width: 820px){
    .all-cards {
      flex-direction: column;
      gap: 40px;
    }

    .all-cards p {
      font-size: 12px;
    }

    .card-images img {
      width: 70px;
    }
  }
</style>