export default {
    state: {
        cars: '',
        colors: '',
    },
    mutations: {
        setCarList(state, payload) {
            state.cars = payload
        },
        setColorList(state, payload) {
            state.colors = payload
        }
    },
    actions: {
        getCarList({dispatch, commit}, filter) {
            const data = (filter !== undefined) ? filter : 'vehicle_type=AUTOMOBILE'
            dispatch('sendRequest', {method: 'GET', endpoint: 'car/list/?' + data})
            .then(response => {
                if (response.status === 502) {
                    commit('notification', {message: 'Что то пошло не так, попробуйте обновить страницу.'})
                }
                return response.json()
            })
            .then(data => {
                if (data?.count > 0) {
                    commit('setCarList', data)
                } else {
                    commit('setCarList', '')
                }
            })
        },
        getColorList({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: 'car/color_list/'})
              .then(response => response.json())
              .then(data => {
                  commit('setColorList', data)
              })
        },
    },
    getters: {
        getCarList(state) {
            return state.cars
        },
        getColorList(state) {
            return state.colors
        }
    }
}