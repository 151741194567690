<template>
    <Header></Header>
    <section class="page-header services-bg">
        <div class="container">
            <h1>Как заказать?</h1>
        </div>
    </section>
    <section class="purchase-process purchase-process-bg">
        <div class="container">
            <h1 class="title">Процедура покупки</h1>
            <div class="purchase-process-icon"></div>
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="purchase-process-wrap counter-line">
                        <b class="purchase-process-counter">01</b>
                        <span class="purchase-process-name">Внесение депозита</span>
                        <p class="purchase-process-text">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt massa aliquam sed enim rhoncus. Id nullam sollicitudin aliquet in.</p>
                    </div>
                    <div class="purchase-process-wrap counter-line">
                        <b class="purchase-process-counter">02</b>
                        <span class="purchase-process-name">Поиск авто</span>
                        <p class="purchase-process-text">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt massa aliquam sed enim rhoncus. Id nullam sollicitudin aliquet in.</p>
                    </div>
                    <div class="purchase-process-wrap counter-line">
                        <b class="purchase-process-counter">03</b>
                        <span class="purchase-process-name">Проверка авто</span>
                        <p class="purchase-process-text">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt massa aliquam sed enim rhoncus. Id nullam sollicitudin aliquet in.</p>
                    </div>
                    <div class="purchase-process-wrap counter-line">
                        <b class="purchase-process-counter">04</b>
                        <span class="purchase-process-name">Ставка</span>
                        <p class="purchase-process-text">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt massa aliquam sed enim rhoncus. Id nullam sollicitudin aliquet in.</p>
                    </div>
                    <div class="purchase-process-wrap">
                        <b class="purchase-process-counter">05</b>
                        <span class="purchase-process-name">Покупка авто</span>
                        <p class="purchase-process-text">Quis fringilla convallis et vitae volutpat at porttitor. Est tincidunt massa aliquam sed enim rhoncus. Id nullam sollicitudin aliquet in.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="purchase-process">
        <div class="container">
            <h1 class="title">Процедура доставки авто из Канады и США</h1>
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="purchase-process-wrap counter-line">
                        <b class="purchase-process-counter">01</b>
                        <span class="purchase-process-name">Вы оставляете заявку</span>
                        <a class="car-btn blink-animate" v-on:click="showModal">Узнать подробнее</a>
                    </div>
                    <div class="purchase-process-wrap counter-line">
                        <b class="purchase-process-counter">02</b>
                        <span class="purchase-process-name">Вы оставляете заявку</span>
                        <p class="purchase-process-text">Вы можете сами выбрать авто на аукционах, используя наши аккаунты, либо можете поручить процесс подбора нам и получать наилучшие варианты и подходящие Вам предложения в оговоренные сроки и удобным для Вас способом (эл.почта,вотсап,телеграм и .т.д.)</p>
                    </div>
                    <div class="purchase-process-wrap">
                        <b class="purchase-process-counter">03</b>
                        <span class="purchase-process-name">Участвуем в торгах</span>
                        <p class="purchase-process-text">Грузим в контейнер 3-4 машины и доставляем по морю и суше в Кыргызстан. Техническое оснащение и высокотонные крепежи прочно фиксируют любой транспорт</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="purchase-process-wrap counter-line">
                        <b class="purchase-process-counter">04</b>
                        <span class="purchase-process-name">Покупаем и оформляем</span>
                        <p class="purchase-process-text">Грузим в контейнер 3-4 машины и доставляем по морю и суше в Кыргызстан. Техническое оснащение и высокотонные крепежи прочно фиксируют любой транспорт</p>
                    </div>
                    <div class="purchase-process-wrap counter-line">
                        <b class="purchase-process-counter">05</b>
                        <span class="purchase-process-name">Загружаем и отправляем</span>
                        <p class="purchase-process-text">Грузим в контейнер 3-4 машины и доставляем по морю и суше в Кыргызстан. Техническое оснащение и высокотонные крепежи прочно фиксируют любой транспорт</p>
                    </div>
                    <div class="purchase-process-wrap">
                        <b class="purchase-process-counter">06</b>
                        <span class="purchase-process-name">Встречаем и разгружаем</span>
                        <p class="purchase-process-text">Грузим в контейнер 3-4 машины и доставляем по морю и суше в Кыргызстан. Техническое оснащение и высокотонные крепежи прочно фиксируют любой транспорт</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="purchase-process-wrap counter-line">
                        <b class="purchase-process-counter">07</b>
                        <span class="purchase-process-name">Расстаможиваем</span>
                        <p class="purchase-process-text">Грузим в контейнер 3-4 машины и доставляем по морю и суше в Кыргызстан. Техническое оснащение и высокотонные крепежи прочно фиксируют любой транспорт</p>
                    </div>
                    <div class="purchase-process-wrap counter-line">
                        <b class="purchase-process-counter">08</b>
                        <span class="purchase-process-name">Сертификация и оформление</span>
                        <p class="purchase-process-text">Грузим в контейнер 3-4 машины и доставляем по морю и суше в Кыргызстан. Техническое оснащение и высокотонные крепежи прочно фиксируют любой транспорт</p>
                    </div>
                    <div class="purchase-process-wrap">
                        <b class="purchase-process-counter">09</b>
                        <span class="purchase-process-name">Автомобиль вашей мечты теперь стал реальностью!</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="trust-us d-none">
        <div class="container">
            <h1 class="title light">Вы можете обратится в транспортную компанию, которая пообещает самые низкие цены на рынке</h1>
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="about-feature-wrap-v2">
                        <i class="about-feature-icon-v2 icon3"></i>
                        <p class="about-feature-text-v2">Некачественный подбор авто</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="about-feature-wrap-v2">
                        <i class="about-feature-icon-v2 icon5"></i>
                        <p class="about-feature-text-v2">Потеря времени, здововья и денег</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="about-feature-wrap-v2">
                        <i class="about-feature-icon-v2 icon6"></i>
                        <p class="about-feature-text-v2">Непрозрачная покупка Отсутствие документации</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="about-feature-wrap-v2">
                        <i class="about-feature-icon-v2 icon4"></i>
                        <p class="about-feature-text-v2">Потеря транспорта в пути</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="about-feature-wrap-v2">
                        <i class="about-feature-icon-v2 icon2"></i>
                        <p class="about-feature-text-v2">Дифицит конроля по покупке, погрузке и перевозке авто</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="about-feature-wrap-v2">
                        <i class="about-feature-icon-v2 icon1"></i>
                        <p class="about-feature-text-v2">Незафиксированный cрок доставки</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Contacts></Contacts>
    <Footer class="border-top"></Footer>
</template>

<script>
import { useHead } from '@vueuse/head'
import Header from '@/components/Header.vue'
import Contacts from '@/components/Contacts.vue'
import Footer from '@/components/Footer.vue'

export default {
    setup() {
        useHead({
            title: 'Услуги - AutoCanada',
        })
    },
    created() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    components: {
        Header,
        Contacts,
        Footer
    },
    methods: {
        showModal() {
            this.$store.dispatch('showModal')
        }
    }
}
</script>