<template>
    <section class="contacts">
        <div class="container">
            <h1 class="title light">Оставайтесь на связи</h1>
            <div class="row">
                <div class="col-md-4 col-12 contacts-form-wrap">
                    <Form v-on:submit="sendContactForm" :validation-schema="validate" ref="form" class="contact-form" autocomplete="off">
                        <div class="form-group">
                            <label>Номер телефона</label>
                            <Field name="phone" type="text" class="form-control rounded-0" v-model="phone" v-mask="phoneMask" placeholder="Введите ваш номер телефона" />
                            <div class="error-input-wrap">
                                <ErrorMessage class="error-wrap" name="phone" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Электронная почта</label>
                            <Field name="email" type="email" class="form-control rounded-0" v-model="email" placeholder="Электронная почта" />
                            <div class="error-input-wrap">
                                <ErrorMessage class="error-wrap" name="email" />
                            </div>
                        </div>
                        <button type="submit" class="custom-button blink-animate block">Отправить</button>
                    </Form>                
                </div>
                <div class="col-md-8 col-12 contacts-image-wrap">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="contact-info phone">
                                <a href="tel:+996554888484">+996 (554) 888 484</a>
                                <a href="tel:+996550926904">+996 (550) 926 904</a>
                                <a href="tel:+14316888484">+1 431 688 84 84</a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="contact-info schudle">
                                <span>Пн-Пт <b>08:00-19:00</b></span>
                                <span>Сб <b>08:00-16:00</b></span>
                                <span>Вс <b>Выходной</b></span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="contact-info email">
                                <a href="mailto:autocanadakg@gmail.com">autocanadakg@gmail.com</a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="contact-info address">
                                <a href="https://go.2gis.com/p8ogd" target="_blank">Бишкек, ул. Суюмбаева 43 офис 4</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as Yup from 'yup'

export default {
    data() {
        return {
            email: '',
            phone: '',
            phoneMask: '+996 ### ### ###',
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        validate() {
            return Yup.object({
                email: Yup.string().required('Поле обязательное для заполнения').email('Введите E-mail'),
                phone: Yup.string().required('Поле обязательное для заполнения')
            })
        }
    },
    methods: {
        sendContactForm() {
            if(this.$refs.form.validate()) {
                const data = {
                    email: this.email,
                    phone: this.phone
                }
                this.$store.dispatch('feedBackForm', data)
                this.phone = ''
                this.email = ''
            }
        }
    }
}
</script>