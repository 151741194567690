<template>
  <section class="search-brand">
    <div class="container">
      <div class="d-flex justify-content-center">
        <div class="search-tabs">
          <span class="search-tab" v-on:click="selectSection('marks')" :class="(section === 'marks') && 'active'">По маркам</span>
          <span class="search-tab" v-on:click="selectSection('body')" :class="(section === 'body') && 'active'">По кузову</span>
        </div>
      </div>
      <div class="row d-flex justify-content-center" v-if="section === 'marks'">
        <template v-for="(item, index) in brandImage" :key="index">
          <div class="search-bran-icon col-md-2 col-4" v-if="index <= 10 || fullList" v-on:click="selectMark(item)">
            <div class="search-brand-img" :class="(mark === item.id) && 'active'">
              <img :src="item.image" v-if="item.image">
            </div>
          </div>
        </template>
        <div class="search-bran-icon col-md-2 col-4" v-on:click="showMore">
          <div class="search-brand-text" v-if="fullList">Скрыть</div>
          <div class="search-brand-text" v-else>Еще</div>
        </div>
      </div>
      <div class="row d-flex justify-content-center" v-if="section === 'body'">
        <template v-for="(item, index) in bodyImage" :key="index">
          <div class="search-bran-icon col-md-4 col-6" v-if="index <= 10 || fullList" v-on:click="selectBody(item)">
            <div class="search-body-img" :class="(body === item.id) && 'active'">
              <img :src="item.image" v-if="item.image">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </template>
        <div class="search-bran-icon col-md-4 col-6" v-on:click="showMore">
          <div class="search-brand-text" v-if="fullList">Скрыть</div>
          <div class="search-brand-text" v-else>Еще</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      section: 'marks',
      fullList: false,
      mark: '',
      body: ''
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    },
    brandImage() {
      if (this.$store.getters.getBrandImage) {
        return this.$store.getters.getBrandImage
      }

      this.$store.dispatch('getBrandImage')

      return this.$store.getters.getBrandImage
    },
    bodyImage() {
      return (this.$store.getters.getBodyImage)
          ? this.$store.getters.getBodyImage
          : this.$store.dispatch('getBodyImage')
    }
  },
  methods: {
    selectMark(item) {
      this.mark = item.id
      this.$router.push('/auction?brand=' + item.title)
      document.getElementById('app').scrollIntoView({behavior: 'smooth'})
    },
    selectBody(item) {
      this.body = item.id
      this.$router.push('/auction?body=' + item.id)
      document.getElementById('app').scrollIntoView({behavior: 'smooth'})
    },
    selectSection(item) {
      this.section = item
      if (!this.$store.getters.getBodyImage) {
        this.$store.dispatch('getBodyImage')
      }
      
    },
    showMore() {
      this.fullList = !this.fullList && true
    }
  }
}
</script>