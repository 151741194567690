<template>
  <Header class="light"></Header>
  <section :class="detail ? 'detail' : 'white-back'">
    <div class="box-wrap" v-if="!detail?.title">
      <h3 class="wrap-title text-center">Машина не найдена или попробуйте перезагрузить страницу</h3>
    </div>
    <div class="container" v-if="detail?.title">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="detail-wrap">
            <div class="detail-slider" :class="MainSliderFullSize && 'fullscreen'">
              <div v-if="MainSliderFullSize" class="close-fullscreen" v-on:click="MainSliderFullSize = false"></div>
              <div v-if="MainSliderFullSize" class="close-fullscreen-icon" v-on:click="MainSliderFullSize = false"></div>
              <div v-if="MainSliderFullSize" class="slider-next-btn" v-on:click="sliderNav('next')"></div>
              <div v-if="MainSliderFullSize" class="slider-prev-btn" v-on:click="sliderNav('prev')"></div>
              <div v-if="MainSliderFullSize" class="detail-main-slider">
                <span :class="`favorite-btn${detail.favorite ? ' favorite-btn-active' : ''}`" @click="addFavorite"/>
                <img :src="DetailMainSlide" alt="Car" v-on:click="MainSliderFullSize = true">
              </div>
              <div class="slider" v-if="!MainSliderFullSize">
                <splide :options="sliderOptions" ref="slider">
                  <splide-slide v-for="slide in detail.images" :key="slide.id">
                    <span :class="`favorite-btn${detail.favorite ? ' favorite-btn-active' : ''}`" @click="addFavorite"/>
                    <img :src="slide.image" alt="slide" v-on:click="MainSliderFullSize = true">
                  </splide-slide>
                </splide>
              </div>
              <Splide :options="thumbsOptions" class="custom-slider-thumb">
                <SplideSlide v-for="(slide, index) in detail.images" :key="index" @click="MainSliderFullSize ? updateMainSlider(slide, index) : onSlideClick(slide)">
                  <img :src="slide.image" :alt="detail.title">
                </SplideSlide>
              </Splide>
            </div>
            <div class="detail-name">
              <div class="detail-title-block">
                <h1 class="detail-title">{{ detail.title }}</h1>
                <span class="detail-view">Просмотров: {{ detail.view_count }}</span>
              </div>
              <div class="detail-price-block">
                <span class="detail-price" v-if="detail.lot_status?.final_price && parseFloat(detail.lot_status.final_price) !== 0">
                  Финальная цена: ${{ detail.lot_status.final_price }}
                </span>
                <span class="detail-price" v-else>
                  Текущая ставка: ${{ detail.price }}
                </span>
              </div>
            </div>
            <div class="detail-info pb-3">
              <h3>Технические характеристики</h3>
              <div class="row car-info">
                <div class="col-md-6 col-6">
                  <i class="fuel"></i>
                  <b>{{ detail.fuel ? detail.fuel : '-' }}</b>
                  <span>Топливо</span>
                </div>
                <div class="col-md-6 col-6">
                  <i class="year"></i>
                  <b>{{ detail.year ? detail.year : '-' }}</b>
                  <span>Год</span>
                </div>
              </div>
              <div class="row car-info pb-2">
                <div class="col-md-6 col-6">
                  <i class="mileage"></i>
                  <b>{{ detail.mileage ? detail.mileage : '-' }} км</b>
                  <span>Пробег</span>
                </div>
                <div class="col-md-6 col-6">
                  <i class="volume"></i>
                  <b>{{ detail.engine ? detail.engine : '-' }}</b>
                  <span>Двигатель</span>
                </div>
              </div>
              <div class="row car-info pb-2">
                <div class="col-md-6 col-6">
                  <i class="body"></i>
                  <b>{{ detail.body ? detail.body : '-' }}</b>
                  <span>Кузов</span>
                </div>
                <div class="col-md-6 col-6">
                  <i class="transmission"></i>
                  <b>{{ detail.transmission ? detail.transmission : '-' }}</b>
                  <span>КПП</span>
                </div>
              </div>
              <div class="row car-info pb-2">
                <div class="col-md-6 col-6">
                  <i class="drive"></i>
                  <b>{{ detail.drive ? detail.drive : '-' }}</b>
                  <span>Привод</span>
                </div>
                <div class="col-md-6 col-6">
                  <i class="color"></i>
                  <b>{{ detail.color ? detail.color : '-' }}</b>
                  <span>Цвет</span>
                </div>
              </div>
              <h3 v-if="detail.description">Дополнительно</h3>
              <p class="detail-text pb-3" v-if="detail.description">{{ detail.description }}</p>
              <h3 class="wrap-title report-title">Технический паспорт</h3>
              <div class="report-info" v-if="detail.certificate?.VIN !== null">
                <span>VIN:</span>
                <b>{{ detail.certificate.VIN }}</b>
              </div>
              <div class="report-info" v-if="detail.certificate?.primary_damage !== null">
                <span>Основные повреждения:</span>
                <b class="text-capitalize">{{ detail.certificate.primary_damage }}</b>
              </div>
              <div class="report-info" v-if="detail.certificate?.secondary_damage !== null && detail.certificate?.secondary_damage !== 'None'">
                <span>Повреждение:</span>
                <b class="text-capitalize">{{ detail.certificate.secondary_damage }}</b>
              </div>
              <div class="report-info" v-if="detail.certificate?.highlights !== null">
                <span>Состояние:</span>
                <b class="text-capitalize">{{ detail.certificate.highlights }}</b>
              </div>
              <div class="report-info" v-if="detail.certificate?.document_type !== null">
                <span>Vehicle Type:</span>
                <b class="text-capitalize">{{ detail.certificate.document_type }}</b>
              </div>
              <div class="report-info" v-if="detail.certificate?.cylinder !== null">
                <span>Cylinders:</span>
                <b class="text-capitalize">{{ detail.certificate.cylinder }}</b>
              </div>
              <div class="report-info" v-if="detail.certificate?.drive_unit !== null">
                <span>Drive:</span>
                <b class="text-capitalize">{{ detail.certificate.drive_unit }}</b>
              </div>
              <div class="report-info" v-if="detail.certificate?.title_sale !== null">
                <span>Title/Sale Doc Brand:</span>
                <b class="text-capitalize">{{ detail.certificate.title_sale }}</b>
              </div>
              <div class="report-info" v-if="detail.certificate?.title_code !== null">
                <span>Title Code:</span>
                <b>{{ detail.certificate.title_code }}</b>
              </div>
              <div class="report-info" v-if="detail.certificate?.airbags !== null">
                <span>Airbags:</span>
                <b class="text-lowercase">
                  {{ detail.certificate.airbags }}
                </b>
              </div>
              <div class="report-info">
                <span>Год:</span>
                <b class="text-capitalize">{{ detail.year ? detail.year : '-' }}</b>
              </div>
              <div class="report-info">
                <span>Реальный пробег:</span>
                <b class="text-capitalize">{{ detail.mileage ? detail.mileage : '-' }} км</b>
              </div>
              <div class="report-info">
                <span>Ключи:</span>
                <b class="text-capitalize">{{
                    (detail.certificate) && (detail.certificate.keys.toLowerCase() === 'true' || detail.certificate.keys.toLowerCase() === 'yes') ? 'Да' : 'Нет'
                  }}</b>
              </div>
              <div class="report-info">
                <span>Местоположение:</span>
                <b class="text-capitalize">{{ detail.certificate?.location || '-' }}</b>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="box-wrap mb-4">
            <h3 class="wrap-title text-center pb-3">Лот №{{ detail.vehicle_id }}</h3>
            <div class="auction-date-wrap" v-if="(timer.days || timer.hours || timer.minutes || timer.seconds) && (!detail.lot_status?.final_price || parseFloat(detail.lot_status.final_price) === 0)">
              <span class="wrap-subtitle">До начала аукциона осталось:</span>
              <div class="auction-date">
                <span v-if="timer.days === 1 || timer.days === 0">{{ timer.days }} день</span>
                <span v-else-if="timer.days > 1 && timer.days < 5">{{ timer.days }} дня</span>
                <span v-else>{{ timer.days }} дней</span>
                <span v-if="timer.hours === 1 || timer.hours === 0">{{ timer.hours }} час</span>
                <span v-else-if="timer.hours > 1 && timer.hours < 5">{{ timer.hours }} часа</span>
                <span v-else>{{ timer.hours }} часов</span>
                <span>{{ timer.minutes }} минут</span>
                <span>{{ timer.seconds }} секунд</span>
              </div>
            </div>
            
            <!-- Show final price when it exists and is not zero -->
            <div v-if="detail.lot_status?.final_price && parseFloat(detail.lot_status.final_price) !== 0" class="row">
              <div class="col-md-12">
                <label class="form-label">Финальная цена, USD</label>
                <input type="text" class="form-input text-danger"
                       :value="'$' + detail.lot_status.final_price" disabled>
              </div>
            </div>
            
            <!-- Show bidding form only when final_price is zero or doesn't exist -->
            <div v-else>
              <div v-if="detail.lot_status?.buy_now_price === '0.00'">
                <div class="row">
                  <div class="col-md-6" v-if="detail.lot_status.date_auction">
                    <label class="form-label">Дата аукциона</label>
                    <input type="text" class="form-input"
                           :value="String(detail.lot_status.date_auction).replace('  ', 'г.,') + 'ч.'"
                           disabled>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Текущая ставка, USD</label>
                    <input type="text" class="form-input text-danger"
                           :value="'$' + (detail.price || '0')" disabled>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="pb-3">
                      <label class="form-label">Ваша ставка, USD</label>
                      <input type="number" class="form-input" v-model="bid" v-on:focus="bid = bid <= 0 ? null : bid">
                    </div>
                  </div>
                  <div class="col-md-6 personal-btn-block">
                    <button class="personal-btn" @click="showModalAccept">Отправить ставку</button>
                  </div>
                </div>
              </div>
              <ModalAcceptBid
                  v-if="modalAccept"
                  :close="showModalAccept"
                  :car_bid="car_bid"
                  :data="{
                    lot: detail.vehicle_id,
                    year: detail.year,
                    vin: detail.certificate?.VIN || '',
                    brand: detail.brand_title,
                    model: detail.model_title,
                    bid: bid,
                  }"
              />
              <div class="row" v-if="detail.lot_status?.buy_now_price !== '0.00'">
                <div class="col-md-6" v-if="detail.lot_status.date_auction">
                  <label class="form-label">Дата аукциона</label>
                  <input type="text" class="form-input"
                         :value="String(detail.lot_status.date_auction).replace('  ', 'г.,') + 'ч.'"
                         disabled>
                </div>
                <div class="col-md-6" v-if="detail.lot_status.date_auction">
                  <label class="form-label">Текущая ставка, USD</label>
                  <input type="text" class="form-input text-danger"
                         :value="'$' + (detail.price || '0')" disabled>
                </div>
                <div class="col-md-6">
                  <div class="pb-3">
                    <label class="form-label">Ваша ставка, USD</label>
                    <input type="number" class="form-input" v-model="bid" v-on:focus="bid = bid <= 0 ? null : bid">
                  </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <button class="personal-btn mt-3" @click="showModalAccept">Отправить ставку</button>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label" style="font-weight: 600; color: #A02C12">Buy now (Купить сейчас), USD</label>
                  <input type="text" class="form-input text-danger"
                         :value="'$' + (detail.lot_status.buy_now_price || '0')" disabled>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <button type="button" class="personal-btn mt-3" v-on:click="showModal">Оставить
                    заявку
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="box-wrap mb-4">
            <p class="text-center mb-0" v-if="deliveryCountry">Для получения информации, прошу обраться в службу
              поддержки</p>
            <h3 class="wrap-title" v-else>Цена в Кыргызстане:
              <b>USD {{ detail.calculator ? detail.calculator.total : '0' }}</b></h3>
          </div>
          <div>
            <div class="box-wrap mb-4">
              <h3 class="wrap-title text-center pb-3">Калькулятор расчета стоимости авто</h3>
            <div class="row">
              <div class="col-md-6">
                <label class="form-label">Ваша ставка</label>
                <input type="number" class="form-input" v-model="bid" v-on:focus="bid = bid <= 0 ? null : bid">
              </div>
              <div class="col-md-6">
                <label class="form-label">Кузов</label>
                <select class="form-input" v-model="body">
                  <template v-for="body in bodyList" :key="body">
                    <option :value="body.id">{{ body.title }}</option>
                  </template>
                </select>
              </div>
            </div>
            <label class="form-label">Аукцион</label>
            <select class="form-input" v-model="auction" disabled>
              <template v-for="auction in auctions" :key="auction">
                <option :value="auction.id">{{ auction.title }}</option>
              </template>
            </select>
            <label class="form-label">Местоположение аукциона</label>
            <select class="form-input" v-model="city" disabled>
              <template v-for="city in auctionCities" :key="city">
                <option :value="city.id +':'+ city.zip_code +':'+ city.location">{{ city.location }}</option>
              </template>
            </select>
            <label class="form-label">Отправка из порта</label>
            <select class="form-input" v-model="portCanada">
              <option value="0">---</option>
              <template v-for="port in portCanadaList" :key="port">
                <option :value="port.id">{{ port.title || 'Не найдено' }} - $ {{
                    port.price || 'Не назначено'
                  }}
                </option>
              </template>
            </select>
            <label class="form-label">Cтрана назначения</label>
            <select class="form-input" v-model="countryDestination">  
              <template v-for="country in countryDestinationList" :key="country">
                <option :value="country.id">{{ country.title }}</option>
              </template>
            </select>
            <label class="form-label">Порт назначения</label>
            <select class="form-input" v-model="port">
              <template v-for="port in portDestinationList" :key="port">
                <option :value="port.id">{{ port.title }}</option>
              </template>
            </select>
          </div>
          <div class="box-wrap mb-4" v-if="detail.calculator">
            <div class="report-info justify-content-between">
              <b>Стоимость авто на аукционе</b>
              <span>USD {{ detail.calculator ? detail.calculator.car_price : '0' }}</span>
            </div>
            <div class="report-info justify-content-between">
              <b>Аукционный сбор</b>
              <span>USD {{ detail.calculator ? detail.calculator.auction_fee : '0' }}</span>
            </div>
            <div class="report-info justify-content-between"
                 v-if="detail.calculator.canada_sales_tax || detail.calculator.canada_sales_tax === 0">
              <b style="gap: 0">Налог (
                <span class="text-danger logo-in-text">
                  покрывается AutoCanada
                </span>)
              </b>
              <span class="text-underline">USD {{ ((detail.calculator.car_price + detail.calculator.auction_fee) * 0.13).toFixed(0) }}</span>
            </div>
            <div class="report-info" v-if="detail.calculator && detail.calculator.canada_sales_tax > 0">
              <small class="d-block">Покрывается Auto Canda KG</small>
            </div>
            <div class="report-info justify-content-between">
              <b>Экспортное оформление</b>
              <span>USD {{ detail.calculator.paperwork_fee ? detail.calculator.paperwork_fee : '0' }}</span>
            </div>
            <div class="report-info justify-content-between">
              <b>Доставка в порт</b>
              <span>USD {{ detail.calculator.delivery ? detail.calculator.delivery : '0' }}</span>
            </div>
            <div class="report-info justify-content-between">
              <b>МОРСКАЯ ПЕРЕВОЗКА</b>
              <span>USD {{ detail.calculator.sea_transportation ? detail.calculator.sea_transportation : '0' }}</span>
            </div>
            <div class="report-info justify-content-between">
              <b>НАЗЕМНАЯ ПЕРЕВОЗКА</b>
              <span>USD {{ detail.calculator.land_transportation ? detail.calculator.land_transportation : '0' }}</span>
            </div>
            <div class="report-info justify-content-between">
              <div class="tool-tip">
                <p class="text-center">Cтраховка автомобиля (1.5%)**</p>
              </div>
              <b class="text-danger">
                <div class="radio-btn" :class="containerInsurance && 'active'"
                     v-on:click="containerInsurance = !containerInsurance"></div>
                <u>С учётом страховки</u></b>
              <span>{{ containerInsurance ? 'USD ' + detail.calculator.container_insurance : '---' }}</span>
            </div>
            <div class="report-info justify-content-between">
              <div class="tool-tip">
                <p class="text-center">Сумма таможенных платежей может отличаться на момент таможенного оформления.</p>
              </div>
              <b class="text-danger">
                <div class="radio-btn" :class="custom && 'active'" v-on:click="custom = !custom"></div>
                <u v-on:click="showCalculator" class="cursor-help">С учётом таможни</u></b>
              <span>{{ custom ? 'USD ' + detail.calculator.custom : '---' }}</span>
            </div>
            <div class="report-info justify-content-between">
              <b>Услуга <span class="text-danger">Auto Canada KG</span></b>
              <span>USD {{ detail.calculator ? detail.calculator.service_auto_canada : '0' }}</span>
            </div>
          </div>
            <div class="box-wrap mb-4">
              <p class="text-center mb-0" v-if="deliveryCountry">Для получения информации, прошу обраться в службу
                поддержки</p>
              <h3 class="wrap-title" v-else-if="detail.lot_status?.final_price && parseFloat(detail.lot_status.final_price) !== 0">
                Финальная цена: <b>USD {{ detail.lot_status.final_price }}</b>
              </h3>
              <h3 class="wrap-title" v-else>
                Итоговая стоимость авто: <b><span class="USD">USD:</span> {{
                    (detail.calculator && detail.calculator.total > 0) ? detail.calculator.total.toFixed(2) : '0'
                  }}</b></h3>
            </div>
            <button type="button" class="custom-button block blink-animate report-button" v-on:click="showModal">Оставить
              заявку
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Contacts></Contacts>
  <Footer></Footer>
  <div class="overflow" v-if="calculatorStatus">
    <div class="close-modal" v-on:click="closeCalculator"></div>
    <div class="modal-window white">
      <Form v-on:submit="onSubmit" :validation-schema="validate" ref="form" autocomplete="off">
        <h3>Расчет таможенных платежей</h3>
        <div class="form-group pb-4">
          <label>Год выпуска авто</label>
          <select class="form-control" v-model="year">
            <option v-for="i in 24" :key="i" :value="years(i)">{{ years(i) }}</option>
          </select>
        </div>
        <div class="form-group pb-4">
          <label>Тип двигателя</label>
          <select class="form-control" v-model="fuel">
            <option v-for="fuel in fuels" :key="fuel" :value="fuel.value">{{ fuel.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label>Объем двигателя, куб. л. (например 2.0)</label>
          <Field
              name="calculatorVolume"
              type="number"
              @input="inputVolume"
              class="form-control"
              v-model="volume"
              @keydown.up="increment"
              @keydown.down="decrement"
              step="0.1"
          />
          <div class="error-input-wrap">
            <ErrorMessage class="error-wrap" name="calculatorVolume"/>
          </div>
        </div>
        <div class="form-group pb-4">
          <label>Итого</label>
          <input type="text" class="form-control" :value="detail?.calculator.custom" disabled="disabled">
        </div>
        <button type="submit" class="custom-button blink-animate block">Добавить в расчет</button>
      </Form>
    </div>
  </div>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate'
import { ref } from 'vue';
import * as yup from 'yup'
import {useHead} from '@vueuse/head'
import {useTimer} from 'vue-timer-hook'
import {Splide, SplideSlide} from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
import Header from '@/components/Header.vue'
import Contacts from '@/components/Contacts.vue'
import Footer from '@/components/Footer.vue'
import ModalAcceptBid from "@/components/ModalAcceptBid.vue";

export default {
  data() {
    return {
      yearMin: 1999,
      yearMax: new Date().getFullYear() + 1,
      thumbsOptions: {
        rewind: true,
        fixedWidth: 100,
        fixedHeight: 65,
        isNavigation: true,
        gap: '5px',
        focus: 'center',
        pagination: false,
        cover: true,
        arrows: false,
        breakpoints: {
          640: {
            fixedWidth: 66,
            fixedHeight: 38,
          },
        },
      },
      sliderOptions: {
        type: 'slide',
        rewind: true,
        perPage: 1, 
        perMove: 1,
        pagination: false, 
        arrows: true,
        autoplay: false, 
      },
      fuels: [
        {name: 'Бензин', value: 'Flexible Fuel', slug: 'Flexible_Fuel'},
        {name: 'Дизель', value: 'Diesel', slug: 'Diesel'},
        {name: 'Газ', value: 'Gasoline', slug: 'Gasoline'},
        {name: 'Гибрид', value: 'Hybrid', slug: 'Hybrid'},
        {name: 'Электрический', value: 'Electric', slug: 'Electric'},
        {name: 'Другое', value: 'Other', slug: 'Other'}
      ],
      timer: '',
      auction: '',
      city: 0,
      price: '',
      year: '',
      volume: '',
      fuel: '',
      body: 1,
      bid: '',
      containerInsurance: false,
      custom: false,
      countryDestination: 1,
      port: 1,
      portCanada: 0,
      zip_code: 1,
      location: '',
      calculatorStatus: false,
      calculatorVolume: '',
      calculatorYear: '',
      MainSliderFullSize: false,
      DetailMainSlide: '',
      CurrentSlideIndex: 0,
      deliveryCountry: false,
      currency: 'CAD',
      modalAccept: false,
    }
  },
  components: {
    ModalAcceptBid,
    Form,
    Field,
    ErrorMessage,
    Splide,
    SplideSlide,
    Header,
    Contacts,
    Footer
  },
  setup() {
    const value = ref(0.1);

    const increment = () => {
      value.value += 0.1;
    };

    const decrement = () => {
      value.value -= 0.1;
    };

    return {
      value,
      increment,
      decrement
    };
  },
  created() {
    this.scrollToTop()
    this.$store.dispatch('getDetail', this.$router.currentRoute.value.params.id)
  },
  computed: {
    detail() {
      const copyDetail = { ...this.$store.getters.getDetail }
      const airbagsStr = copyDetail?.certificate?.airbags
      if (airbagsStr && typeof airbagsStr === "string") {
        try {
          const newStr = JSON.parse(airbagsStr?.replace(/'/g, '"'));
          copyDetail.certificate.airbags = `driver: ${newStr.Driver}, passenger: ${newStr.Passenger}, other: ${newStr.Other}`
          console.log('airbagsStr', airbagsStr)
          console.log('newStr', newStr)
        } catch (e) {
          console.log(e)
        }
      }
      console.log('DETAIL_DATA', copyDetail);
      return copyDetail
    },
    auctions() {
      return (this.$store.getters.getAuctionList)
          ? this.$store.getters.getAuctionList
          : this.$store.dispatch('getAuctionList')
    },
    auctionCities() {
      return this.$store.getters.getCityList
    },
    bodyList() {
      return (this.$store.getters.getCalculatorBodyList)
          ? this.$store.getters.getCalculatorBodyList
          : this.$store.dispatch('getCalculatorBodyList')
    },
    countryDestinationList() {
      return (this.$store.getters.getCountryDestination)
          ? this.$store.getters.getCountryDestination
          : this.$store.dispatch('getCountryDestination')
    },
    portDestinationList() {
      return (this.$store.getters.getPortDestination)
          ? this.$store.getters.getPortDestination
          : this.$store.dispatch('getPortDestination')
    },
    portCanadaList() {
      return this.$store.getters.getPortCanada
    },
    calculateResult() {
      return this.$store.getters.getCalculateResult
    },
    validate() {
      return yup.object({
        calculatorVolume: yup.number().required('Поле обязательное для заполнения')
      })
    }
  },
  watch: {
    detail() {
      useHead({
        title: 'AutoCanada - ' + this.detail?.title + ' ' + this.detail?.year
      })

      if (this.detail.images?.length > 0) {
        const ogImageUrl = this.detail.images[0].image;

        // Перед добавлением новых мета-тегов, удаляем все существующие мета-теги og:image из head
        this.removeExistingMetaTags('og:image');
        this.removeExistingMetaTags('og:url');
        this.removeExistingMetaTags('og:image:alt');

        // Добавляем новый мета-тег og:image в head
        this.addMetaTag('og:image', ogImageUrl);
        this.addMetaTag('og:url', window.location.href);
        this.addMetaTag('og:title', `Горячий лот для тебя: ${this.detail.title}`);
      }

      if (this.detail) {
        this.auction = this.detail.auction
        this.price = this.detail.price
        this.year = this.detail.year
        this.volume = this.detail.volume
        this.fuel = (Object(this.fuels).filter(e => e.slug === this.detail.fuel).length > 0) ? this.detail.fuel : 'Other'
        this.bid = this.detail.price || 0

        this.$store.dispatch('getCityList', this.detail.auction)
        this.DetailMainSlide = this.detail.images && this.detail.images[0].image
      }

      /* --- auction date --- */
      if (this.detail?.lot_status) {
        const auctionDate = this.detail.lot_status.date_auction?.replaceAll('   ', ' ')
        if (!auctionDate) {
          return
        }
        const time = auctionDate.split(' ')
        const date = time[0].split('.')
        const dateFormat = date[2] + '/' + date[1] + '/' + date[0] + ' ' + time[1]
        const auctionStartDate = new Date(dateFormat)
        this.timer = useTimer(auctionStartDate.setSeconds(auctionStartDate.getSeconds()))
      }
      /* --- auction date --- */
    },
    custom() {
      if (!this.custom) {
        this.detail.calculator.total = this.detail.calculator.total - this.detail.calculator.custom
      } else {
        this.detail.calculator.total = this.detail.calculator.total + +this.detail.calculator.custom
      }
    },
    containerInsurance() {
      if (!this.containerInsurance) {
        this.detail.calculator.total = this.detail.calculator.total - this.detail.calculator.container_insurance
      } else {
        this.detail.calculator.total = this.detail.calculator.total + +this.detail.calculator.container_insurance
      }
    },
    bodyList() {
      this.body = this.$store.getters.getCalculatorBodyList[0].id
    },
    calculateResult() {
      this.detail.calculator = this.$store.getters.getCalculateResult
      if (this.custom) {
        this.detail.calculator.total = this.detail.calculator.total + +this.detail.calculator.custom
      }
      if (this.containerInsurance) {
        this.detail.calculator.total = this.detail.calculator.total + +this.detail.calculator.container_insurance
      }
    },
    bid() {
      this.calculate()
    },
    body() {
      this.calculate()
    },
    auction() {
      this.$store.dispatch('getCityList', this.auction)
      this.calculate()
    },
    city() {
      var value = String(this.city).split(':')
      this.location = value[0]
      const data = new URLSearchParams({
        auction: this.auction,
        zip_code: value[1],
        location: value[2],
        body: this.body,
      })
      this.$store.dispatch('getPortCanada', data)
      this.calculate()
    },
    countryDestination() {
      if (this.countryDestination === 1) {
        this.deliveryCountry = false
        this.calculate()
      } else {
        this.deliveryCountry = true
      }
    },
    port() {
      this.calculate()
    },
    portCanada() {
      this.calculate()
    },
    auctionCities() {
      const location = Object(this.$store.getters.getCityList) &&
          Object(this.$store.getters.getCityList).find(item => item.location === this.detail.certificate.location)
      this.city = location.id + ':' + location.zip_code + ':' + location.location
      this.location = location.id
    },
    portCanadaList() {
      this.portCanada = this.$store.getters.getPortCanada[0]?.id
    }
  },
  methods: {
    removeExistingMetaTags(property) {
      const existingMetaTags = document.querySelectorAll(`meta[property="${property}"]`);
      existingMetaTags.forEach((tag) => tag.remove());
    },
    addMetaTag(property, content) {
      const metaTag = document.createElement('meta');
      metaTag.setAttribute('property', property);
      metaTag.setAttribute('content', content);
      document.head.appendChild(metaTag);
    },
    scrollToCalculator() {
      document.getElementById('calculator').scrollIntoView({behavior: 'smooth'})
    },
    scrollToTop() {
      document.getElementById('app').scrollIntoView({behavior: 'smooth'})
    },
    years(count) {
      return this.yearMax - count
    },
    calculate() {
      const data = {
        auction: this.auction,
        city: this.location,
        price: this.bid,
        year: this.year ? this.year : null,
        volume: this.volume ? this.volume : null,
        fuel: this.fuel,
        body: this.body,
        'port_canada': this.portCanada,
        'port_destination': this.port,
        'country_destination': this.countryDestination,
        currency: this.currency,
      }
      this.$store.dispatch('calculate', data)
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        const data = {
          auction: this.auction,
          city: this.location,
          price: this.bid,
          year: this.year ? this.year : null,
          volume: this.volume ? this.volume : null,
          fuel: this.fuel,
          body: this.body,
          'port_canada': this.portCanada,
          'port_destination': this.port,
          'country_destination': this.countryDestination,
        }
        this.$store.dispatch('calculate', data)
        this.detail.calculator = this.$store.getters.getCalculateResult
      }
    },
    showModal() {
      this.$store.dispatch('showModal')
    },
    showCalculator() {
      this.calculatorStatus = true
    },
    closeCalculator() {
      this.calculatorStatus = false
    },
    updateMainSlider(item, index) {
      this.CurrentSlideIndex = index
      this.DetailMainSlide = item.image
    },
    sliderNav(action) {
      if (action === 'next') {
        if (this.detail.images[this.CurrentSlideIndex + +1] !== undefined) {
          this.CurrentSlideIndex = this.CurrentSlideIndex + +1
          this.DetailMainSlide = this.detail.images[this.CurrentSlideIndex].image
        } else {
          this.CurrentSlideIndex = 0
          this.DetailMainSlide = this.detail.images[0].image
        }
      } else {
        if (this.detail.images[this.CurrentSlideIndex - 1] !== undefined) {
          this.CurrentSlideIndex = this.CurrentSlideIndex - 1
          this.DetailMainSlide = this.detail.images[this.CurrentSlideIndex].image
        }
      }
    },
    check(value) {
      this.currency = value
      this.calculate()
    },
    async addFavorite() {
      const user = this.$store.getters.getUser

      if (!user) {
        this.$store.commit('openModalLogin', true)
        return
      }

      await this.$store.dispatch('addFavoriteCar', {car_id: this.detail.id})
      await this.$store.dispatch('getDetail', this.$router.currentRoute.value.params.id)

      setTimeout(() => {
        const message = this.$store.getters.getFavoriteMessage

        if (message === 'deleted') {
          this.$store.commit('notification', {message: 'Машина удалена из избранных!'})
        } else if (message === 'added') {
          this.$store.commit('notification', {message: 'Машина добавлена в избранное!', type: 'success'})
        }
      }, 800)
    },
    async car_bid() {
      await this.$store.dispatch('postCarBid', {car: this.detail.id, bid_price: this.bid})
      await this.$store.dispatch('getUserDetail', 'user')
      await this.$store.dispatch('getDetail', this.$router.currentRoute.value.params.id)
      const fetchMessage = this.$store.getters.getCarBidMessage

      if (fetchMessage === 'Ваша ставка успешно отправлена!') {
        this.modalAccept = false
      }

      setTimeout(() => {
        this.$store.commit('notification', {message: fetchMessage, type: fetchMessage === 'Ваша ставка успешно отправлена!' ? 'success' : 'error'})
      }, 800)
    },
    showModalAccept() {
      const user = this.$store.getters.getUser

      if (!user) {
        this.$store.commit('openModalLogin', true)
        return
      }

      if (this.bid < this.detail.price + 100 && !this.modalAccept) {
        this.$store.commit('notification', {message: 'Ваша ставка должна быть на 100$ выше чем ' + this.detail?.price})
        return
      }


      this.modalAccept = !this.modalAccept
    },
    inputVolume(e) {
      if (e.target.value === '') {
        this.volume = 0;
      }
    },
    onSlideClick(slide) {
      // Получаем ссылку на экземпляр слайдера
      const slider = this.$refs.slider;

      // Находим индекс выбранного слайда
      const index = this.detail.images.findIndex((s) => s.id === slide.id);

      // Переключаем слайдер на соответствующий слайд
      slider.go(index);
    },
  }
}
</script>

<style scoped>
.logo-in-text {
  text-transform: none!important;
}

.white-back {
  height: 960px;
}

.slider {
  height: 500px;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: none;
}

@media screen and (max-width: 720px){
  .slider {
    height: 280px;
  }
}
</style>

