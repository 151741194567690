
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyC-2p1WcQyz-HlBnGXLrbVJxVPppxtVfoM",
  authDomain: "autocanadaback.firebaseapp.com",
  projectId: "autocanadaback",
  storageBucket: "autocanadaback.appspot.com",
  messagingSenderId: "400860284932",
  appId: "1:400860284932:web:e04ff809bbd404e6724d51",
  measurementId: "G-K6LWD8VZGT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export default app