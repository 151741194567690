export default {
  state: {
    user: '',
    email: '',
    showModalRegister: false,
    showModalLogin: false,
    showModalLConfirm: false,
    showModalPasswordRecovery: false,
    favoriteCars: '',
    history: '',
    bid_cars: '',
    bid_data: '',
    my_cars: '',
    passwordStatus: '',
    vinCar: '',
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setEmail(state, payload) {
      state.email = payload
    },
    openModalRegister(state, payload) {
      state.showModalRegister = payload
    },
    openModalLogin(state, payload) {
      state.showModalLogin = payload
    },
    openModalConfirm(state, payload) {
      state.showModalLConfirm = payload
    },
    openModalPasswordRecovery(state, payload) {
      state.showModalPasswordRecovery = payload
    },
    clearUser(state) {
      state.user = ''
      localStorage.clear()
    },
    setFavoriteCars(state, payload) {
      state.favoriteCars = payload
    },
    setHistory(state, payload) {
      state.history = payload
    },
    setBidCars(state, payload) {
      state.bid_cars = payload
    },
    setBidData(state, payload) {
      state.bid_data = payload
    },
    setMyCars(state, payload) {
      state.my_cars = payload
    },
    setPasswordStatus(state, payload) {
      state.passwordStatus = payload
    },
    setVinCar(state, payload) {
      state.vinCar = payload
    },
  },
  actions: {
    phoneVerify({dispatch, commit}, data) {
      dispatch('sendRequest', {endpoint: 'auth/phone_verify/', data})
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            commit('notification', {message: 'Ваш аккаунт успешно подтвержден!', type: 'success'})
            commit('openModalRegister', false)
            commit('openModalLogin', true)
            return res.json()
          } else {
            commit('notification', {message: 'Произошла неизвестная ошибка, попробуйте позже'})
          }
        })
        .then(data => {
          if (data) {
            localStorage.clear()
            const serializedState = JSON.stringify({refresh: data?.token?.refresh, access: data?.token?.access})
            localStorage.setItem('tokens', serializedState)

            location.reload()
          }
        })
    },
    sendCode({dispatch, commit}, data) {
      dispatch('sendRequest', {endpoint: 'auth/send_code/', data})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            commit('notification', {message: 'Код отправлен на вашу почту!', type: 'success'})
            return
          }
          if (data.passwordRecovery) {
            commit('openModalPasswordRecovery', false)
          }
          return response.json()
        })
        .then(data => {
          if (data) {
            commit('notification', {message: data.message})
          }
        })

    },
    setNewPassword({dispatch, commit}, data) {
      dispatch('sendRequest', {method: 'PATCH', endpoint: 'auth/set_new_password/', data})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            commit('notification', {message: 'Пароль успешно восстановлен', type: 'success'})
            commit('openModalPasswordRecovery', false)
            return
          }

          return response.json()
        })
        .then(data => {
          if (data?.non_field_errors) {
            commit('notification', {message: data.non_field_errors[0]})
          }
        })
    },
    setNewPasswordPhone({dispatch, commit}, data) {
      dispatch('sendRequest', {method: 'PATCH', endpoint: 'auth/set_new_password_phone/', data})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            commit('notification', {message: 'Пароль успешно восстановлен', type: 'success'})
            commit('openModalPasswordRecovery', false)
            return
          }

          return response.json()
        })
        .then(data => {
          if (data?.non_field_errors) {
            commit('notification', {message: data.non_field_errors[0]})
          }
        })
    },
    emailVerify({dispatch, commit}, data) {
      dispatch('sendRequest', {endpoint: 'auth/email-verify/', data})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            commit('notification', {message: 'Ваш аккаунт успешно подтвержден!', type: 'success'})
            commit('openModalConfirm', false)
          }
          return response.json()
        })
        .then(data => {
          if (data.error) {
            commit('notification', {message: 'Произошла неизвестная ошибка, попробуйте позже'})
          } else if (data.token) {
            localStorage.clear()
            const serializedState = JSON.stringify({refresh: data?.token?.refresh, access: data?.token?.access})
            localStorage.setItem('tokens', serializedState)

            location.reload()
          } else if (data.non_field_errors) {
            commit('notification', {message: data?.non_field_errors[0]})
          }
        })
    },
    postUser({dispatch, commit}, data) {
      dispatch('sendRequest', {endpoint: 'auth/register/', data})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            commit('notification', {message: 'Успешно отправлено', type: 'success'})
          }
          return response.json()
        })
        .then(data => {
          if (data?.message) {
            commit('notification', {message: data.message})
          } else {
            if (data?.email) {
              commit('openModalRegister', false)
              commit('openModalConfirm', true)
              commit('setEmail', data.email)
            }
          }
        })
    },
    async getUser({dispatch, commit}, data) {
      await dispatch('sendRequest', {endpoint: 'auth/login/', data})
        .then(response => response.json())
        .then(data => {
          if (data?.message) {
            commit('notification', {message: data.message})
            return
          }
          commit('setUser', data?.user_detail)
          commit('openModalLogin', false)
          localStorage.clear()
          const serializedState = JSON.stringify({refresh: data?.tokens?.refresh, access: data?.tokens?.access})
          localStorage.setItem('tokens', serializedState)
        })
    },
    async getUserDetail({dispatch, commit}) {
      const serializedState = localStorage.getItem('tokens')
      if (serializedState === null) {
        return
      }
      const tokens = JSON.parse(serializedState)

      if (!tokens?.access) {
        return
      }

      await dispatch('sendRequest', {method: 'GET', endpoint: 'auth/detail_profile/', tokens})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          }
        })
        .then(data => {
          commit('setUser', data)
        })
    },
    updateUser({dispatch, commit}, data) {
      const serializedState = localStorage.getItem('tokens')
      if (serializedState === null) {
        return
      }
      const tokens = JSON.parse(serializedState)

      if (!tokens) {
        return
      }

      dispatch('sendRequest', {method: 'PUT', endpoint: 'auth/update_profile/', data, tokens})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            commit('notification', {message: 'Данные изменены', type: 'success'})
          }
          return response.json()
        })
        .then(data => {
          if (data?.message) {
            commit('notification', {message: data.message})
          } else {
            commit('setUser', data)
          }
        })
    },
    async updatePassword({dispatch, commit}, data) {
      const serializedState = localStorage.getItem('tokens')
      if (serializedState === null) {
        return
      }
      const tokens = JSON.parse(serializedState)

      if (!tokens) {
        return
      }

      await dispatch('sendRequest', {method: 'PATCH', endpoint: 'auth/change_password/', data, tokens})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            commit('notification', {message: 'Данные изменены', type: 'success'})
          }
          return response.json()
        })
        .then(data => {
          if (data?.message) {
            commit('notification', {message: data.message})
            commit('setPasswordStatus', data.message)
          }
        })
    },
    getFavoriteCars({dispatch, commit}, data) {
      const serializedState = localStorage.getItem('tokens')
      if (serializedState === null) {
        return
      }
      const tokens = JSON.parse(serializedState)

      if (!tokens) {
        return
      }

      let url = {endpoint: `car/my_favorite/?page=${1}`}

      if (data) {
        url = {baseUrl: data}
      }

      dispatch('sendRequest', {method: 'GET', ...url, tokens})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            commit('notification', {message: 'Что то пошло не так!'})
            return ''
          }
        })
        .then(data => {
          if (data) {
            commit('setFavoriteCars', data)
          }
        })
    },
    getHistory({dispatch, commit}, data) {
      const serializedState = localStorage.getItem('tokens')
      if (serializedState === null) {
        return
      }
      const tokens = JSON.parse(serializedState)

      if (!tokens) {
        return
      }

      let url = {endpoint: `auth/history?page=${1}`}

      if (data) {
        url = {baseUrl: data}
      }

      dispatch('sendRequest', {method: 'GET', ...url, tokens})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            commit('notification', {message: 'Что то пошло не так!'})
            return ''
          }
        })
        .then(data => {
          if (data) {
            commit('setHistory', data)
          }
        })
    },
    getBidCars({dispatch, commit}, data) {
      const serializedState = localStorage.getItem('tokens')
      if (serializedState === null) {
        return
      }
      const tokens = JSON.parse(serializedState)

      if (!tokens) {
        return
      }

      let url = {endpoint: `auth/my_bid_car/?page=${1}`}

      if (data) {
        url = {baseUrl: data}
      }

      dispatch('sendRequest', {method: 'GET', ...url, tokens})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            commit('notification', {message: 'Что то пошло не так!'})
            return ''
          }
        })
        .then(data => {
          if (data) {
            commit('setBidCars', data)
          }
        })
    },
    getBidData({dispatch, commit}, data) {
      const serializedState = localStorage.getItem('tokens')
      if (serializedState === null) {
        return
      }
      const tokens = JSON.parse(serializedState)

      if (!tokens) {
        return
      }

      let url = {endpoint: `auth/my_bid/?page=${1}`}

      if (data) {
        url = {baseUrl: data}
      }

      dispatch('sendRequest', {method: 'GET', ...url, tokens})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            commit('notification', {message: 'Что то пошло не так!'})
            return ''
          }
        })
        .then(data => {
          if (data) {
            commit('setBidData', data)
          }
        })
    },
    getMyCars({dispatch, commit}, data) {
      const serializedState = localStorage.getItem('tokens')
      if (serializedState === null) {
        return
      }
      const tokens = JSON.parse(serializedState)

      if (!tokens) {
        return
      }

      let url = {endpoint: `auth/my_auto/?page=${1}`}

      if (data) {
        url = {baseUrl: data}
      }

      dispatch('sendRequest', {method: 'GET', ...url, tokens})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            commit('notification', {message: 'Что то пошло не так!'})
            return ''
          }
        })
        .then(data => {
          if (data) {
            commit('setMyCars', data)
          }
        })
    },
    backBid({dispatch, commit}, data) {
      const serializedState = localStorage.getItem('tokens')

      if (serializedState === null) {
        return
      }

      const tokens = JSON.parse(serializedState)

      if (!tokens) {
        return
      }

      dispatch('sendRequest', {method: 'POST', endpoint: 'auth/withdrawal_create/', data, tokens})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            commit('notification', {message: 'Ваш запрос отправлен!', type: 'success'})
          } else {
            commit('notification', {message: 'Что то пошло не так!'})
          }
        })
    },
    async getCarByVin({dispatch, commit}, data) {
      await dispatch('sendRequest', {method: 'GET', endpoint: `car_main/VIN_search/${data}/`})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            commit('notification', {message: 'Машина по такому VIN не найдена'})
          }
        })
        .then(data => {
          if (data) {
            commit('setVinCar', data) 
          } else {
            commit('setVinCar', [])
          }
        })
    }
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getEmail(state) {
      return state.email
    },
    getShowRegister(state) {
      return state.showModalRegister
    },
    getShowLogin(state) {
      return state.showModalLogin
    },
    getShowConfirm(state) {
      return state.showModalLConfirm
    },
    getShowPasswordRecovery(state) {
      return state.showModalPasswordRecovery
    },
    getFavoriteCars(state) {
      return state.favoriteCars
    },
    getHistory(state) {
      return state.history
    },
    getBidCars(state) {
      return state.bid_cars
    },
    getBidData(state) {
      return state.bid_data
    },
    getMyCars(state) {
      return state.my_cars
    },
    getPasswordStatus(state) {
      return state.passwordStatus
    },
    getVinCar(state) {
      return state.vinCar
    }
  }
}