<template>
  <div class="my-confirm-modal">
    <div class="my-background">
      <div class="btn-modal-close" @click="close('refund')"/>
      <div class="my-login-modal-content">
        <p>1. Введение.</p>
        <p>
          Целью настоящей политики использования веб-сайта является обеспечение безопасного, эффективного и этичного
          использования
          ресурсов и услуг, предоставляемых нашим веб-сайтом. Политика использования устанавливает правила и
          ограничения,
          которые должны соблюдать все пользователи во время взаимодействия с нашим веб-сайтом.
        </p>
        <p>
          Веб-сайт предоставляет различные функции и услуги, предназначенные для удовлетворения потребностей и интересов
          пользователей.
          Политика использования устанавливает правила доступа к сайту, условия использования и ответственности, которые
          пользователи должны принять и соблюдать.
        </p>
        <p>
          Соблюдение политики использования является обязательным для всех пользователей нашего веб-сайта. Это важно для
          обеспечения безопасной и надежной среды, защиты прав и интересов пользователей, а также поддержания высокого
          уровня качества предоставляемых услуг.
        </p>
        <p>
          Мы призываем всех пользователей ознакомиться с политикой использования и строго следовать ее положениям.
          В случае нарушения политики, могут быть применены соответствующие санкции, вплоть до ограничения или
          прекращения доступа к веб-сайту.
        </p>
        <p>
          Понимание и соблюдение политики использования является взаимным обязательством как для администрации
          веб-сайта, так и для всех пользователей.
          Мы стремимся создать удобную и безопасную среду для всех наших пользователей и благодарим за сотрудничество в
          соблюдении политики использования веб-сайта.
        </p>
        <p>
          Обращаем ваше внимание на то, что политика использования может быть периодически изменена или обновлена.
          Рекомендуется
          регулярно знакомится с последней версией политики, чтобы быть в курсе актуальной информации.
        </p>
        <p>2. Определение пользователей.</p>
        <p>
          В рамках политики использования веб-сайта, различаются следующие категории пользователей, для которых
          применяются определенные обязательства и правила:
        </p>
        <p>
          <span style="font-style: italic">Дилеры:</span> Дилеры являются зарегистрированными пользователями, которые
          создают учетную запись на веб-сайте.
          Они имеют доступ к специальным функциям и услугам, предоставляемым веб-сайтом. Дилеры обязаны предоставлять
          точные и актуальные данные при регистрации, а также обеспечивать безопасность своей учетной записи. Они несут
          ответственность за все действия, совершаемые на своей учетной записи.
        </p>
        <p>
          <span style="font-style: italic">Посетители:</span> Посетители - это пользователи, которые посещают веб-сайт
          без регистрации или создания учетной записи.
          Они имеют ограниченный доступ к некоторым функциям и информации на веб-сайте. Посетители также обязаны
          соблюдать
          правила и ограничения, установленные в политике использования.
        </p>
        <p>3. Обязательства и правила для каждой категории пользователей.</p>
        <p>
          <span style="font-style: italic">Дилеры:</span>
          <ul>
            <li>
              Дилеры обязаны создать учетную запись, предоставив точные и актуальные данные.
            </li>
            <li>
              Минимальный возраст для регистрации и использования сайта составляет 18 лет.
            </li>
            <li>
              Дилеры несут ответственность за сохранность своего логина и пароля.
            </li>
            <li>
              Запрещается передавать логин и пароль своей учетной записи третьим лицам.
            </li>
            <li>
              Дилеры несут ответственность за все действия, включая ставки и представленные виртуальные ставки, совершаемые на их учетной записи.
            </li>
            <li>
              Дилеры обязуются использовать веб-сайт в соответствии с законами и правилами соответствующих юрисдикций.
            </li>
            <li>
              Дилерам необходимо предотвращать доступ других лиц или третьих сторон к их учетной записи.
            </li>
            <li>
              Дилеры обязаны немедленно уведомлять о любом несанкционированном использовании своего пароля или учетной записи, а также о нарушениях безопасности.
            </li>
            <li>
              Дилеры несут ответственность за конфиденциальность своих данных, предоставленных при регистрации и использовании сайта.
            </li>
          </ul>
        </p>
        <p>
          <span style="font-style: italic">Посетители:</span>
          <ul>
            <li>Посетители обязаны соблюдать правила доступа и использования информации на веб-сайте.</li>
            <li>Посетители несут ответственность за любые действия, совершаемые в рамках использования веб-сайта.</li>
          </ul>
        </p>
        <p>
          Политика использования веб-сайта применяется ко всем пользователям, включая дилеров и посетителей, и
          предоставляет основные правила и обязательства, которые должны соблюдаться при взаимодействии с нашим веб-сайтом.
        </p>
        <p>Условия доступа и использования</p>

        <p>
          <span style="font-style: italic">Получение доступа к веб-сайту:</span>
          <ul>
            <li>
              Для использования функций и услуг веб-сайта необходимо создать учетную запись.
            </li>
            <li>
              При регистрации необходимо предоставить точные, актуальные и полные данные.
            </li>
            <li>
              Минимальный возраст для регистрации и использования веб-сайта составляет 18 лет.
            </li>
          </ul>
          <span style="font-style: italic">Ограничения использования:</span>
          <ul>
            <li>
              Пользователи обязаны использовать веб-сайт в соответствии с законами и правилами Республики Кыргызстан и других соответствующих юрисдикций.
            </li>
            <li>
              Запрещено использование учетной записи третьих лиц.
            </li>
            <li>
              Пользователям необходимо предотвращать доступ других лиц или третьих сторон к их учетной записи.
            </li>
          </ul>
          <span style="font-style: italic">Возрастные ограничения:</span>
          <ul>
            <li>Минимальный возраст для регистрации и использования веб-сайта составляет 18 лет.</li>
            <li>
              Лица, не достигшие минимального возраста, не имеют право использовать веб-сайт и его функции.
            </li>
          </ul>
          <span style="font-style: italic">Защита личных данных:</span>
          <ul>
            <li>
              Личная информация и данные, предоставленные пользователями при регистрации и использовании веб-сайта,
              обрабатываются в соответствии с политикой конфиденциальности.
            </li>
            <li>
              Веб-сайт обязуется защищать конфиденциальность пользователей и не передавать их данные третьим лицам
              без согласия пользователей, за исключением случаев, предусмотренных законодательством.
            </li>
          </ul>
          <span style="font-style: italic">Конфиденциальность:</span>
          <ul>
            <li>
              Все данные и личная информация, предоставленные пользователями при регистрации и использовании веб-сайта,
              обрабатываются в соответствии с политикой конфиденциальности веб-сайта.
            </li>
            <li>
              Пользователям рекомендуется ознакомиться с политикой конфиденциальности для получения подробной информации
              о сборе, использовании и защите их личных данных.
            </li>
          </ul>
        </p>

        <p>Ограничения и запреты</p>
        <p>В рамках политики использования веб-сайта введены следующие ограничения и запреты на определенные действия:</p>
        <span style="font-style: italic">Незаконная активность:</span>
        <ul>
          <li>
            Запрещено совершать любые действия на веб-сайте, которые противоречат действующему законодательству
            Кыргызской Республики и другим соответствующим юрисдикциям.
          </li>
          <li>
            Запрещено осуществлять мошенничество, взлом, фишинг или любые другие противоправные действия.
          </li>
        </ul>
        <span style="font-style: italic">Нарушение авторских прав и интеллектуальной собственности:</span>
        <ul>
          <li>
            Запрещено нарушать авторские права, патенты, товарные знаки и другие формы интеллектуальной собственности на веб-сайте.
          </li>
          <li>
            Запрещено загружать, публиковать или распространять материалы, защищенные авторскими правами,
            без соответствующего разрешения правообладателя.
          </li>
        </ul>
        <span style="font-style: italic">Распространение вредоносного контента:</span>
        <ul>
          <li>
            Запрещено загружать, публиковать или распространять вредоносный контент, включая вирусы, вредоносные программы,
            шпионское ПО или другие вредоносные элементы.
          </li>
          <li>
            Запрещено совершать действия, которые могут причинить ущерб или нарушить безопасность веб-сайта или его пользователей.
          </li>
        </ul>
        <span style="font-style: italic">Неприемлемое поведение:</span>
        <ul>
          <li>
            Запрещено совершать действия, которые могут нанести вред репутации веб-сайта или других пользователей,
            включая оскорбления, дискриминацию, угрозы или непристойное поведение.
          </li>
          <li>
            Запрещено использовать веб-сайт для распространения незаконного, оскорбительного, порнографического или иного неприемлемого контента.
          </li>
        </ul>
        <p>ПОСЛЕДСТВИЯ НАРУШЕНИЯ ПОЛИТИКИ ИСПОЛЬЗОВАНИЯ:</p>
        <ul>
          <li>
            В случае нарушения политики использования, веб-сайт оставляет за собой право принять меры, включая
            ограничение или блокировку доступа пользователя к веб-сайту.
          </li>
          <li>
            Пользователь, нарушивший политику использования, может быть подвергнут санкциям или юридическим
            последствиям в соответствии с действующим законодательством.
          </li>
        </ul>

        <p>Обязательства пользователя</p>
        <p>При использовании веб-сайта AUTOCANADA.KG, каждый пользователь обязуется соблюдать следующие обязательства:</p>

        <span style="font-style: italic">Соблюдение законов и правил:</span>
        <ul>
          <li>
            Пользователь обязуется использовать веб-сайт в соответствии с действующим законодательством Кыргызской Республики и других соответствующих юрисдикций.
          </li>
          <li>
            Пользователь обязуется использовать веб-сайт в соответствии с действующим законодательством Кыргызской Республики и других соответствующих юрисдикций.
          </li>
        </ul>

        <span style="font-style: italic">Уважение прав и свобод других пользователей:</span>
        <ul>
          <li>
            Пользователь обязуется уважать права и свободы других пользователей веб-сайта.
          </li>
          <li>
            Запрещено совершать действия, которые могут оскорбить, нарушить конфиденциальность или нанести вред другим пользователям.
          </li>
        </ul>
        <span style="font-style: italic">Использование веб-сайта только в личных или разрешенных целях:</span>
        <ul>
          <li>
            Пользователь обязуется использовать веб-сайт и его функции только в личных или разрешенных целях.
          </li>
          <li>
            Запрещено использование веб-сайта для коммерческих, незаконных или противоправных целей.
          </li>
        </ul>
        <span style="font-style: italic">Соблюдение политики конфиденциальности:</span>
        <ul>
          <li>
            Пользователь обязуется соблюдать политику конфиденциальности веб-сайта и обращаться с личными данными
            других пользователей в соответствии с этой политикой.
          </li>
          <li>
            Запрещено незаконный сбор, использование или распространение личных данных других пользователей.
          </li>
        </ul>
        <span style="font-style: italic">Сообщение о нарушениях:</span>
        <ul>
          <li>
            Пользователь обязуется немедленно уведомлять администрацию веб-сайта о любом подозрении на нарушение политики использования или безопасности веб-сайта.
          </li>
          <li>
            Пользователь может использовать предоставленные на веб-сайте контактные данные для связи с администрацией.
          </li>
        </ul>

        <p>Процедура жалоб и санкций</p>

        <span style="font-style: italic">Подача жалоб на нарушения:</span>
        <ul>
          <li>
            Пользователи, обнаружившие нарушения политики использования веб-сайта AUTOCANADA.KG, имеют право подать жалобу администрации веб-сайта.
          </li>
          <li>
            Жалоба может быть подана путем связи с администрацией через контактные данные, указанные на веб-сайте.
          </li>
          <li>
            Жалоба должна содержать детали о нарушении, включая описание событий, идентификацию нарушителя (если известно) и любые доступные доказательства.
          </li>
        </ul>
        <span style="font-style: italic">Рассмотрение жалоб:</span>
        <ul>
          <li>
            Администрация веб-сайта AUTOCANADA.KG обязуется рассматривать все поданные жалобы в разумные сроки.
          </li>
          <li>
            В процессе рассмотрения жалобы администрация может потребовать дополнительную информацию или доказательства от жалующегося пользователя.
          </li>
          <li>
            В случае необходимости, администрация может провести внутреннее расследование для выяснения обстоятельств нарушения.
          </li>
        </ul>
        <span style="font-style: italic">Применение санкций:</span>
        <ul>
          <li>
            В случае подтверждения нарушения политики использования, администрация веб-сайта AUTOCANADA.KG
            имеет право применить соответствующие санкции в отношении нарушителя.
          </li>
          <li>
            Возможные санкции включают, но не ограничиваются, следующими мерами:
          </li>
          <li>
            Предупреждение: Выдача предупреждения нарушителю с указанием нарушенной политики и требования исправить поведение.
          </li>
          <li>
            Ограничение доступа: Временное или постоянное ограничение доступа нарушителя к определенным функциям или всему веб-сайту.
          </li>
          <li>
            Блокировка учетной записи: Полная блокировка учетной записи нарушителя с запретом доступа к веб-сайту.
          </li>
          <li>
            Юридические последствия: Передача информации о нарушителе компетентным органам для уголовного преследования,
            если нарушение имеет юридическую значимость.
          </li>
        </ul>
        <span style="font-style: italic">Уведомление об итогах:</span>
        <ul>
          <li>
            После рассмотрения жалобы и применения санкций, администрация веб-сайта AUTOCANADA.KG
            уведомит жалующегося пользователя о результатах рассмотрения жалобы.
          </li>
          <li>
            В случае применения санкций, объяснение причин и мер, принятых в отношении нарушителя, может быть предоставлено жалующемуся пользователю.
          </li>
        </ul>

        <p>
          Процедура жалоб и санкций разработана для обеспечения справедливого и безопасного использования веб-сайта
          AUTOCANADA.KG. Пользователи могут быть уверены, что их жалобы будут рассмотрены и приняты во внимание,
          а нарушители будут подвергнуты соответствующим санкциям в случае подтверждения нарушения политики использования
        </p>

        <p>Изменения в политике</p>

        <span style="font-style: italic">Возможность изменения политики использования:</span>
        <ul>
          <li>
            Веб-сайт AUTOCANADA.KG оставляет за собой право вносить изменения в политику использования со временем.
          </li>
          <li>
            Изменения могут быть внесены по различным причинам, включая улучшение безопасности, соответствие законодательству и изменение функциональности веб-сайта.
          </li>
          <li>
            Новая версия политики использования вступает в силу с момента ее публикации на веб-сайте.
          </li>
        </ul>
        <span style="font-style: italic">Уведомление о внесенных изменениях:</span>
        <ul>
          <li>
            Пользователи будут уведомлены о внесенных изменениях в политику использования следующими способами уведомления, но не ограничиваться:
          </li>
          <li>
            Публикация обновленной политики использования на веб-сайте с указанием даты вступления в силу.
          </li>
          <li>
            Отправка уведомления по электронной почте зарегистрированным пользователям, указанной при регистрации на веб-сайте.
          </li>
          <li>
            Отправка уведомления по электронной почте зарегистрированным пользователям, указанной при регистрации на веб-сайте.
          </li>
        </ul>
        <span style="font-style: italic">Обязательство ознакомления с изменениями:</span>
        <ul>
          <li>
            Важно, чтобы пользователи регулярно знакомились с политикой использования, чтобы быть в курсе последних изменений.
          </li>
          <li>
            Продолжение использования веб-сайта после вступления в силу обновленной политики использования означает согласие пользователя с внесенными изменениями.
          </li>
        </ul>

        <p>
          Изменения в политике использования могут происходить с течением времени, чтобы обеспечить соответствие
          законодательству и лучшую защиту интересов пользователей. Пользователи будут уведомлены о таких изменениях,
          и им рекомендуется ознакомиться с обновленной политикой использования для полного понимания своих прав и обязательств
          при использовании веб-сайта AUTOCANADA.KG.
        </p>

        <p>Заключительные положения</p>

        <span style="font-style: italic">Соблюдение политики использования:</span>
        <ul>
          <li>
            Пользователи веб-сайта AUTOCANADA.KG обязаны соблюдать политику использования во время своего взаимодействия с сайтом.
          </li>
          <li>
            Политика использования разработана для обеспечения безопасной, этичной и ответственной среды для всех пользователей.
          </li>
        </ul>
        <span style="font-style: italic">Взаимодействие с поддержкой пользователей:</span>
        <ul>
          <li>
            В случае возникновения вопросов или проблем, пользователи могут обратиться в службу поддержки веб-сайта.
          </li>
          <li>
            Служба поддержки готова предоставить необходимую помощь и руководство в отношении политики использования.
          </li>
        </ul>
        <span style="font-style: italic">Обновление контактной информации:</span>
        <ul>
          <li>
            Пользователи обязаны обновлять свою контактную информацию, чтобы быть в курсе последних уведомлений и изменений политики использования.
          </li>
          <li>
            Пользователи могут обновить свою контактную информацию в своем профиле на веб-сайте.
          </li>
        </ul>
        <span style="font-style: italic">Правовая юрисдикция:</span>
        <ul>
          <li>
            Политика использования веб-сайта AUTOCANADA.KG регулируется и толкуется в соответствии с законодательством Кыргызской Республики.
          </li>
        </ul>
        <span style="font-style: italic">Прием политики использования:</span>
        <ul>
          <li>
            Использование веб-сайта AUTOCANADA.KG подразумевает принятие политики использования и согласие с ее условиями.
          </li>
          <li>
            Если пользователь не согласен с политикой использования, он должен прекратить использование веб-сайта.
          </li>
        </ul>

        <p>
          Заключительные положения политики использования направлены на обеспечение соблюдения правил,
          установленных для использования веб-сайта AUTOCANADA.KG, и создание безопасной и надежной среды
          для всех пользователей. Пользователям рекомендуется ознакомиться с политикой использования и следовать ее
          указаниям во время использования веб-сайта.
        </p>

      </div>
      <button type="button" class="custom-button blink-animate block" @click="close('refund', true)">
        Принять
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['close'],
};
</script>

<style scoped>
.my-login-modal-content {
  height: 800px;
  overflow: auto;
  padding: 40px 80px 120px;
}

.btn-modal-close {
  display: block!important;
  z-index: 9;
}

.my-background {
  position: relative;
  background: #fff;
}

.my-login-modal-content {
  background: linear-gradient(to top, transparent, white 30%, white 95%, transparent);
  -webkit-mask-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 1)), to(rgba(255, 255, 255, 0)));
  mask-image: linear-gradient(to top, transparent, white 30%, white 95%, transparent);
}

.my-login-modal-content::-webkit-scrollbar {
  width: 8px;
  background: #F5F4F6;
}

.my-login-modal-content::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
}

.my-login-modal-content::-webkit-scrollbar-thumb {
  background: #A02C12;
  border-radius: 10px;
}

.my-confirm-modal h2 {
  color: #000;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.my-confirm-modal p {
  font-size: 16px;
  line-height: 185%;
}

.my-confirm-modal p span {
  text-decoration: underline;
}

.my-background button {
  position: absolute;
  bottom: 30px;
  left: 80px;
  width: 80%;
}

@media screen and (max-width: 820px) {
  .my-background {
    width: 80%;
  }

  .my-login-modal-content {
    width: 100%;
    height: 600px;
    margin-right: 0;
  }

  .my-confirm-modal h2 {
    font-size: 18px;
  }

  .my-confirm-modal .my-login-modal-content p {
    font-size: 12px;
    line-height: 16px;
  }

  .my-background button {
    bottom: 30px;
    left: 30px;
  }
}
</style>