<template>
  <Header class="light" theme="light"></Header>
  <section class="calculator">
    <div class="container">
      <h3 class="wrap-title text-center">Калькулятор расчета стоимости авто</h3>
      <div class="row pt-4">
        <div class="col-md">
          <Form
            v-on:submit="calculate"
            :validation-schema="validateMain"
            autocomplete="off"
            class="box-wrap mb-4"
          >
            <div class="row">
              <div class="col-md-3 col-6">
                <label class="form-label">Цена авто</label>
                <Field
                  name="bid"
                  type="number"
                  class="form-input mb-0"
                  v-model="bid"
                  @input="handlePriceChange"
                />
                <div class="error-input-wrap">
                  <ErrorMessage class="error-wrap" name="bid" />
                </div>
              </div>
              <div class="col-md-3 col-6 country-check">
                <div class="country-check-in">
                  <span
                    @click="check('USA')"
                    :class="`usa${
                      this.currency === 'USA' ? ' check-active' : ''
                    }`"
                    >$</span
                  >
                  <span
                    @click="check('CAD')"
                    :class="`canada${
                      this.currency === 'CAD' ? ' check-active' : ''
                    }`"
                    >$</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-label">Кузов</label>
                <Field
                  name="body"
                  as="select"
                  class="form-input mb-0"
                  v-model="body"
                >
                  <template v-for="body in bodyList" :key="body">
                    <option :value="body.id">{{ body.title }}</option>
                  </template>
                </Field>
                <div class="error-input-wrap">
                  <ErrorMessage class="error-wrap" name="body" />
                </div>
              </div>
            </div>
            <label class="form-label">Выберите аукцион</label>
            <Field
              name="auction"
              as="select"
              class="form-input mb-0"
              v-model="auction"
            >
              <template v-for="auction in auctions" :key="auction">
                <option :value="auction.id">{{ auction.title }}</option>
              </template>
            </Field>
            <div class="error-input-wrap">
              <ErrorMessage class="error-wrap" name="auction" />
            </div>
            <label class="form-label">Местоположение аукциона</label>
            <Field
              name="city"
              as="select"
              class="form-input mb-0"
              v-model="city"
            >
              <option value="0">---</option>
              <template v-for="city in auctionCities" :key="city">
                <option
                  :value="city.id + ':' + city.zip_code + ':' + city.location"
                >
                  {{ city.location }}
                </option>
              </template>
            </Field>
            <div class="error-input-wrap">
              <ErrorMessage class="error-wrap" name="city" />
            </div>
            <label class="form-label">Отправка из порта</label>
            <select class="form-input" v-model="portCanada">
              <option value="0">---</option>
              <template v-for="port in portCanadaList" :key="port">
                <option :value="port.id">
                  {{
                    port.title ||
                    "Не найдено" + " - $" + port.price ||
                    "Не назначено"
                  }}
                </option>
              </template>
            </select>
            <label class="form-label">Cтрана назначения</label>
            <select class="form-input" v-model="countryDestination">
              <template
                v-for="country in countryDestinationList"
                :key="country"
              >
                <option :value="country.id">{{ country.title }}</option>
              </template>
            </select>
            <label class="form-label">Порт назначения</label>
            <select class="form-input" v-model="port">
              <template v-for="port in portDestinationList" :key="port">
                <option :value="port.id">{{ port.title }}</option>
              </template>
            </select>
          </Form>
        </div>
        <div class="col-md">
          <div class="box-wrap mb-4">
            <div class="report-info justify-content-between">
              <b>Стоимость авто</b>
              <span
                >USD
                {{
                  calculator?.calculation_data?.car_price
                    ? calculator.calculation_data?.car_price
                    : "0"
                }}</span
              >
            </div>
            <div class="report-info justify-content-between">
              <b>Аукционный сбор</b>
              <span
                >USD
                {{
                  calculator?.calculation_data?.auction_fee
                    ? calculator?.calculation_data?.auction_fee
                    : "0"
                }}</span
              >
            </div>
            <div
              class="report-info justify-content-between"
              v-if="
                calculator?.calculation_data?.canada_sales_tax ||
                calculator?.calculation_data?.canada_sales_tax === 0
              "
            >
              <b style="gap: 0"
                >Налог (
                <span class="text-danger logo-in-text">
                  покрывается AutoCanada </span
                >)
              </b>
              <span class="text-underline"
                >USD
                {{
                  (
                    (calculator?.calculation_data?.car_price +
                      calculator?.calculation_data?.auction_fee) *
                    0.13
                  ).toFixed(0)
                }}</span
              >
            </div>
            <div class="report-info justify-content-between">
              <b>Экспортное оформление</b>
              <span
                >USD
                {{
                  calculator?.calculation_data?.paperwork_fee
                    ? calculator?.calculation_data?.paperwork_fee
                    : "0"
                }}</span
              >
            </div>
            <div class="report-info justify-content-between">
              <b>Доставка в порт</b>
              <span
                >USD
                {{
                  calculator?.calculation_data?.delivery
                    ? calculator?.calculation_data?.delivery
                    : "0"
                }}</span
              >
            </div>
            <div class="report-info justify-content-between">
              <b
                >ПЕРЕВОЗКА
                <span style="text-transform: lowercase"
                  >(морская, наземная)</span
                ></b
              >
              <span
                >USD
                {{
                  calculator?.calculation_data?.transportation
                    ? calculator?.calculation_data?.transportation
                    : "0"
                }}</span
              >
            </div>
            <div class="report-info justify-content-between">
              <div class="tool-tip">
                <p class="text-center">Cтраховка автомобиля (1.5%)**</p>
              </div>
              <b class="text-danger"
                ><div
                  class="radio-btn"
                  :class="containerInsurance && 'active'"
                  v-on:click="containerInsurance = !containerInsurance"
                ></div>
                <u>С учётом страховки</u></b
              >
              <span>{{
                calculator && containerInsurance
                  ? "USD " + calculator?.calculation_data?.container_insurance
                  : "---"
              }}</span>
            </div>
            <div class="report-info justify-content-between">
              <div class="tool-tip">
                <p class="text-center">
                  Сумма таможенных платежей может отличаться на момент
                  таможенного оформления.
                </p>
              </div>
              <b class="text-danger"
                ><div
                  class="radio-btn"
                  :class="custom && 'active'"
                  v-on:click="custom = !custom"
                ></div>
                <u v-on:click="showCalculator" class="cursor-help"
                  >С учётом таможни</u
                ></b
              >
              <span>{{
                calculator && custom
                  ? "USD " + calculator?.calculation_data?.custom
                  : "---"
              }}</span>
            </div>
            <div class="report-info justify-content-between">
              <b>Услуга <span class="text-danger">Auto Canada KG</span></b>
              <span
                >USD
                {{
                  calculator?.calculation_data?.service_auto_canada
                    ? calculator?.calculation_data?.service_auto_canada
                    : "0"
                }}</span
              >
            </div>
          </div>
          <div class="box-wrap mb-4">
            <p class="text-center mb-0" v-if="deliveryCountry">
              Для получения информации, прошу обраться в службу поддержки
            </p>
            <h3 class="wrap-title" v-else>
              ИТОГОВАЯ СТОИМОСТЬ АВТО:
              <b
                >USD
                {{
                  calculator?.calculation_data?.total &&
                  calculator?.calculation_data?.total > 0
                    ? calculator?.calculation_data?.total.toFixed(2)
                    : "0"
                }}</b
              >
            </h3>
          </div>

          <div class="box-wrap flex items-center">
            <div class="report-info justify-content-between item-center m-0">
              <h3 class="wrap-title">
                ОБРАТНЫЙ <br />
                КАЛЬКУЛЯТОР:
              </h3>
              <div class="flex items-center">
                <span class="me-2">USD</span>
                <input
                  type="number"
                  class="form-input"
                  v-model="price"
                  @blur="
                    calculation_data?.carPrice === calculation_data?.finalPrice
                  "
                  style="width: 150px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Contacts></Contacts>
  <Footer></Footer>
  <div class="overflow" v-if="calculatorStatus">
    <div class="close-modal" v-on:click="closeCalculator"></div>
    <div class="modal-window white">
      <Form
        v-on:submit="onSubmit"
        :validation-schema="validateCar"
        autocomplete="off"
      >
        <h3>Расчет таможенных платежей</h3>
        <div class="form-group pb-3">
          <label>Год выпуска авто</label>
          <Field name="year" as="select" class="form-control" v-model="year">
            <option v-for="i in 24" :key="i" :value="years(i)">
              {{ years(i) }}
            </option>
          </Field>
          <div class="error-input-wrap">
            <ErrorMessage class="error-wrap" name="year" />
          </div>
        </div>
        <div class="form-group pb-3">
          <label>Тип двигателя</label>
          <Field name="fuel" as="select" class="form-control" v-model="fuel">
            <option v-for="fuel in fuels" :key="fuel" :value="fuel.value">
              {{ fuel.name }}
            </option>
          </Field>
          <div class="error-input-wrap">
            <ErrorMessage class="error-wrap" name="fuel" />
          </div>
        </div>
        <div class="form-group pb-3">
          <label>Объем двигателя, куб. л. (например 2.0)</label>
          <Field
            name="volume"
            type="number"
            class="form-control"
            @input="inputVolume"
            v-model="volume"
            @keydown.up="increment"
            @keydown.down="decrement"
            step="0.1"
          />
          <div class="error-input-wrap">
            <ErrorMessage class="error-wrap" name="volume" />
          </div>
        </div>
        <div class="form-group pb-4">
          <label>Итого</label>
          <input
            type="text"
            class="form-control"
            :value="calculator.custom"
            disabled="disabled"
          />
        </div>
        <button type="submit" class="custom-button blink-animate block">
          Подсчитать
        </button>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { ref } from "vue";
import * as yup from "yup";
import Header from "@/components/Header.vue";
import Contacts from "@/components/Contacts.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      yearMin: 1999,
      yearMax: new Date().getFullYear() + 1,
      fuels: [
        { name: "Бензин", value: "Flexible Fuel", slug: "Flexible_Fuel" },
        { name: "Дизель", value: "Diesel", slug: "Diesel" },
        { name: "Газ", value: "Gasoline", slug: "Gasoline" },
        { name: "Гибрид", value: "Hybrid", slug: "Hybrid" },
        { name: "Электрический", value: "Electric", slug: "Electric" },
        { name: "Другое", value: "Other", slug: "Other" },
      ],
      auction: "",
      city: 0,
      year: "",
      volume: "",
      fuel: "",
      body: 1,
      bid: 1000,
      carPrice: 0,
      price: 0,
      containerInsurance: false,
      custom: false,
      countryDestination: 1,
      port: 1,
      portCanada: 0,
      zip_code: 1,
      location: "",
      calculatorStatus: false,
      calculatorVolume: "",
      calculatorYear: "",
      deliveryCountry: false,
      currency: "CAD",
      exchangeRates: {
        USD: 1,
        CAD: 0.75,
      },
      originalBid: 1000,
      isReverseCalculation: false,
    };
  },
  setup() {
    const value = ref(0.1);

    const increment = () => {
      value.value += 0.1;
    };

    const decrement = () => {
      value.value -= 0.1;
    };

    return {
      value,
      increment,
      decrement,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Header,
    Contacts,
    Footer,
  },
  computed: {
    calculator() {
      return this.$store.getters.getCalculateResult;
    },
    auctions() {
      return this.$store.getters.getAuctionList
        ? this.$store.getters.getAuctionList
        : this.$store.dispatch("getAuctionList");
    },
    auctionCities() {
      return this.$store.getters.getCityList;
    },
    bodyList() {
      return this.$store.getters.getCalculatorBodyList
        ? this.$store.getters.getCalculatorBodyList
        : this.$store.dispatch("getCalculatorBodyList");
    },
    countryDestinationList() {
      return this.$store.getters.getCountryDestination
        ? this.$store.getters.getCountryDestination
        : this.$store.dispatch("getCountryDestination");
    },
    portDestinationList() {
      return this.$store.getters.getPortDestination
        ? this.$store.getters.getPortDestination
        : this.$store.dispatch("getPortDestination");
    },
    portCanadaList() {
      return this.$store.getters.getPortCanada;
    },
    validateMain() {
      return yup.object({
        bid: yup.string().required("Поле обязательное для заполнения"),
        body: yup.string().required("Выберите кузов"),
        auction: yup.string().required("Выберите аукцион"),
        city: yup.string().required("Выберите город"),
      });
    },
    validateCar() {
      return yup.object({
        year: yup.string().required("Выберите год"),
        fuel: yup.string().required("Выберите тип двигателя"),
        volume: yup.number().required("Поле обязательное для заполнения"),
      });
    },
  },
  watch: {
    custom() {
      if (this.calculator) {
        if (!this.custom) {
          this.calculator.total =
            this.calculator.total - this.calculator.custom;
        } else {
          this.calculator.total =
            this.calculator.total + +this.calculator.custom;
        }
      }
    },
    finalPrice(newVal) {
      this.carPrice = newVal;
    },
    containerInsurance() {
      if (this.calculator) {
        if (!this.containerInsurance) {
          this.calculator.total =
            this.calculator.total - this.calculator.container_insurance;
        } else {
          this.calculator.total =
            this.calculator.total + +this.calculator.container_insurance;
        }
      }
    },

    auctions() {
      this.auction = this.$store.getters.getAuctionList[0].id;
    },
    bodyList() {
      this.body = this.$store.getters.getCalculatorBodyList[0].id;
    },
    calculator() {
      this.calculator = this.$store.getters.getCalculateResult;
      if (this.custom) {
        this.calculator.total = this.calculator.total + +this.calculator.custom;
      }
      if (this.containerInsurance) {
        this.calculator.total =
          this.calculator.total + +this.calculator.container_insurance;
      }
    },
    bid() {
      if (!this.isReverseCalculation) {
        this.calculate();
      }
    },
    body() {
      this.performCalculation();
    },
    auction() {
      this.$store.dispatch("getCityList", this.auction);
      this.performCalculation();
    },
    city() {
      var value = String(this.city).split(":");
      this.location = value[0];
      const data = new URLSearchParams({
        auction: this.auction,
        zip_code: value[1],
        location: value[2],
        body: this.body,
      });
      this.$store.dispatch("getPortCanada", data);
      this.performCalculation();
    },
    countryDestination() {
      if (this.countryDestination === 1) {
        this.deliveryCountry = false;
        this.performCalculation();
      } else {
        this.deliveryCountry = true;
      }
    },
    port() {
      this.performCalculation();
    },
    portCanada() {
      this.performCalculation();
    },
    auctionCities() {
      // this.city = this.$store.getters.getCityList[0].id +':'+ this.$store.getters.getCityList[0].zip_code +':'+ this.$store.getters.getCityList[0].location
      this.location = this.$store.getters.getCityList[0]?.id;
    },
    portCanadaList() {
      this.portCanada = this.$store.getters.getPortCanada[0]?.id;
    },
  },
  methods: {
    years(count) {
      return this.yearMax - count;
    },
    performCalculation() {
      // Check if we should use reverse calculation
      if (this.finalPrice > 0) {
        this.reverseCalculate();
      } else {
        this.calculate();
      }
    },
    reverseCalculate() {
      // Create data object for the reverse calculation
      const data = {
        auction: this.auction,
        city: this.location,
        final_price: this.finalPrice, // Send the final price instead of bid
        year: this.year ? this.year : null,
        volume: this.volume ? this.volume : null,
        fuel: this.fuel,
        body: this.body,
        port_canada: this.portCanada,
        port_destination: this.port,
        country_destination: this.countryDestination,
        currency: this.currency,
        is_reverse: true, // Flag to indicate this is a reverse calculation
      };

      // Dispatch to a reverse calculate endpoint
      this.$store.dispatch("reverseCalculate", data);
    },
    calculate() {
      const data = {
        auction: this.auction,
        city: this.location,
        price: this.bid,
        year: this.year ? this.year : null,
        volume: this.volume ? this.volume : null,
        fuel: this.fuel,
        body: this.body,
        port_canada: this.portCanada,
        port_destination: this.port,
        country_destination: this.countryDestination,
        currency: this.currency,
      };
      this.$store.dispatch("calculate", data);
    },
    onSubmit() {
      this.performCalculation();
      this.calculator = this.$store.getters.getCalculateResult;
    },
    showCalculator() {
      this.calculatorStatus = true;
    },
    closeCalculator() {
      this.calculatorStatus = false;
    },
    check(value) {
      const oldCurrency = this.currency;
      this.currency = value;

      if (oldCurrency !== value) {
        if (value === "USA" && oldCurrency === "CAD") {
          // Convert from CAD to USD
          this.bid = Math.round(this.bid * this.exchangeRates["CAD"]);

          // Also convert the final price if it's set
          if (this.finalPrice > 0) {
            this.finalPrice = Math.round(
              this.finalPrice * this.exchangeRates["CAD"]
            );
          }
        } else if (value === "CAD" && oldCurrency === "USA") {
          // Convert from USD to CAD
          this.bid = Math.round(this.bid / this.exchangeRates["CAD"]);

          // Also convert the final price if it's set
          if (this.finalPrice > 0) {
            this.finalPrice = Math.round(
              this.finalPrice / this.exchangeRates["CAD"]
            );
          }
        }
      }

      // Always use the appropriate calculation method based on which input has data
      this.performCalculation();
    },
    inputVolume(e) {
      if (e.target.value === "") {
        this.volume = 0;
      }
    },
    handlePriceChange() {
      // Store the original value in the current currency
      this.originalBid = this.bid;
      this.isReverseCalculation = false;
    },
    handleReverseCalculation() {
      // Set flag to indicate we're doing a reverse calculation
      this.isReverseCalculation = true;

      // If there's a value in the reverse calculator, use it
      if (this.finalPrice > 0) {
        this.reverseCalculate();
      } else {
        // If the reverse calculator is cleared, revert to normal calculation
        this.calculate();
      }
    },
  },
  mounted() {
    // Initialize originalBid with the default bid value
    this.originalBid = this.bid;

    // You might want to fetch current exchange rates from an API here
    // For example:
    // fetch('https://api.exchangerate-api.com/v4/latest/USD')
    //   .then(response => response.json())
    //   .then(data => {
    //     this.exchangeRates = {
    //       'USD': 1,
    //       'CAD': data.rates.CAD
    //     };
    //   });
  },
};
</script>

<style scoped>
.logo-in-text {
  text-transform: none !important;
}
</style>
