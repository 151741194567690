<template>
  <div class="container my-bids-con">
    <div class="row row-cols-md-2 custom-cards">
      <div class="car-card car-card-v2 mb-4 custom-card" v-for="car in cars.results" :key="car">
        <div class="row custom-card-in">
          <h3 class="car-title">{{ car.brand +' '+ (car.model ? car.model : '') }}</h3>
          <div class="col-md-5 p-0">
            <div class="car-thumbnail">
              <img :src="car.image" :alt="car.brand" v-if="car.image">
            </div>
          </div>
          <div class="col-md-7">
            <div class="car-body">
              <span class="car-auction-date">Дата аукциона: <b>{{ String(car.auction_date).replace(' ', 'г.,') + 'ч.' }}</b></span>
              <p class="group_data">
                <span class="car-price mb-3">Выиграно за: <b> ${{ car.price }}</b></span>
                <span class="car-vin">VIN: <b>{{ car.vin ? car.vin : '-' }}</b></span>
              </p>
              <div class="row car-info">
                <div class="col-md-4 col-6 mb-3">
                  <i class="mileage"></i>
                  <b>{{ car.mileage ? car.mileage : '0' }} км</b>
                  <span>Пробег</span>
                </div>
                <div class="col-md-4 col-6 mb-3">
                  <i class="year"></i>
                  <b>{{ car.year ? car.year : '-' }}</b>
                  <span>Год</span>
                </div>
                <div class="col-md-4 col-6 mb-3">
                  <i class="transmission"></i>
                  <b>{{ car.transmission ? car.transmission : '-' }}</b>
                  <span>КПП</span>
                </div>
                <div class="col-md-4 col-6 mb-3">
                  <i class="fuel"></i>
                  <b>{{ car.fuel ? car.fuel : '-' }}</b>
                  <span>Топливо</span>
                </div>
                <div class="col-md-4 col-6 mb-3">
                  <i class="volume"></i>
                  <b>{{ car.volume ? car.volume : '-' }}</b>
                  <span>Двигатель</span>
                </div>
                <div class="col-md-4 col-6 mb-3">
                  <i class="body"></i>
                  <b>{{ car.body ? car.body : '-' }}</b>
                  <span>Кузов</span>
                </div>
              </div>
              <div class="car-footer">
                <div class="car-footer-wrap">
                  <div class="car-flag-wrap">
                    <img :src="car.flag" :alt="car.auction">
                    <b>{{ car.auction }}</b>
                  </div>
                </div>
                <router-link :to="'/detail/' + car.id" class="car-btn" target="_blank">Подробнее</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pag-list" v-if="cars?.previous !== null || cars?.next !== null">
      <a class="pag-link pag-arrow-left" :class="cars.previous === null && 'disabled'" v-on:click="paginationCars(cars.previous)">Предыдущая</a>
      <a class="pag-link pag-arrow-right" :class="cars.next === null && 'disabled'" v-on:click="paginationCars(cars.next)">Следующая</a>
    </div>
    <div class="box-wrap" v-if="!(cars?.results?.length !== 0)">
      <h3 class="wrap-title text-center">Ничего не найдено</h3>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('getMyCars')
  },
  computed: {
    cars() {
      console.log(this.$store.getters.getMyCars)
      return this.$store.getters.getMyCars
    },
  },
  methods: {
    check(value) {
      this.active = value
    },
    paginationCars(url) {
      this.$store.dispatch('getMyCars', url)
    },
  }
}
</script>

<style scoped>
.my-bids-con {
  margin-bottom: 200px;
}

.custom-cards {
  gap: 2%;
}

.custom-card {
  width: 48%;
  border-radius: 5px;
  box-shadow: -2px -2px 5px rgba(30, 30, 30, 0.14), 2px 2px 5px rgba(30, 30, 30, 0.14);
  overflow: hidden;
}

.custom-card-in {
  border-radius: 0;
  box-shadow: none;
  overflow: auto;
  padding: 10px 0 20px 26px;
}

.group_data {
  display: flex;
  gap: 10px;
}

.custom-cards span, .custom-cards b {
  font-size: 14px;
}

.custom-cards i {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1280px){
  .custom-cards span, .custom-cards b {
    font-size: 10px;
  }

  .custom-cards i {
    width: 16px;
    height: 16px;
  }

  .custom-cards .car-auction-date {
    margin-bottom: 10px;
  }

  .custom-cards .group_data {
    margin-bottom: 0;
  }

  .custom-cards .car-info {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 960px){
  .custom-card {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 720px){
  .custom-card-in {
    padding: 10px;
  }
}

</style>