export default {
    state: {
        detail: '',
        city: '',
        calculate: '',
        countryDestination: '',
        portDestination: '',
        portCanada: '',
        calculatorBodyList: '',
        favoriteMessage: '',
        carBidMessage: '',
    },
    mutations: {
        setDetail(state, payload) {
            state.detail = payload
        },
        setCityList(state, payload) {
            state.city = payload
        },
        setCalculateResult(state, payload) {
            state.calculate = payload
        },
        setCountryDestination(state, payload) {
            state.countryDestination = payload
        },
        setPortDestination(state, payload) {
            state.portDestination = payload
        },
        setPortCanada(state, payload) {
            state.portCanada = payload
        },
        setCalculatorBodyList(state, payload) {
            state.calculatorBodyList = payload
        },
        setFavoriteMessage(state, payload) {
            state.favoriteMessage = payload
        },
        setCarBidMessage(state, payload) {
            state.carBidMessage = payload
        }
    },
    actions: {
        getDetail({dispatch, commit}, carId) {
            try {
                const serializedState = localStorage.getItem('tokens')

                let tokens = null

                if (serializedState) {
                    tokens = JSON.parse(serializedState)
                }

                dispatch('sendRequest', {method: 'GET', endpoint: 'car/detail/' + carId + '/', tokens})
                  .then(response => {
                      if (response.status >= 200 && response.status < 300) {
                          return response.json()
                      }
                  })
                  .then(data => {
                      commit('setDetail', data)
                  })
            } catch (e) {
                console.log(e)
            }
        },
        async getCityList({dispatch, commit}, auctionId) {
            if (auctionId === undefined) {
                auctionId = ''
            }
            await dispatch('sendRequest', {method: 'GET', endpoint: 'car/city_list/?auction=' + auctionId, loader: false})
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json()
                }
            })
            .then(data => {
                commit('setCityList', data)
            })
        },
        getCalculatorBodyList({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: 'car/body_for_calculator/', loader: false})
            .then(response => response.json())
            .then(data => {
                commit('setCalculatorBodyList', data)
            })
        },
        getCountryDestination({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: 'car/country_destination/'})
            .then(response => response.json())
            .then(data => {
                commit('setCountryDestination', data)
            })
        },
        getPortDestination({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: 'car/port_destination/'})
            .then(response => response.json())
            .then(data => {
                commit('setPortDestination', data)
            })
        },
        getPortCanada({dispatch, commit}, params) {
            const queryParams = params ? '?' + params : ''
            dispatch('sendRequest', {method: 'GET', endpoint: 'car/port_delivery_list/' + queryParams})
            .then(response => response.json())
            .then(data => {
                commit('setPortCanada', data)
            })
        },
        calculate({dispatch, commit}, data) {
            dispatch('sendRequest', {endpoint: 'car/calculator/', data, loader: false})
            .then(response => response.json())
            .then(data => {
                commit('setCalculateResult', data)
            })
        },
        async addFavoriteCar({dispatch, commit}, data) {
            const serializedState = localStorage.getItem('tokens')
            if (serializedState === null) {
                return
            }
            const tokens = JSON.parse(serializedState)

            if (!tokens?.access) {
                return
            }

            await dispatch('sendRequest', {endpoint: 'car/favorite/add_or_remove/', data, tokens})
              .then(response => {
                  if (response.status >= 200 && response.status < 300) {
                      return response.json()
                  } else {
                      commit('notification', {message: 'Что то пошло не так!'})
                  }
              })
              .then(data => {
                  if (data) {
                      commit('setFavoriteMessage', data)
                  }
              })
        },
        async postCarBid({dispatch, commit}, data) {
            const serializedState = localStorage.getItem('tokens')
            if (serializedState === null) {
                return
            }
            const tokens = JSON.parse(serializedState)

            if (!tokens?.access) {
                return
            }

            await dispatch('sendRequest', {endpoint: 'auth/carbid_create_or_update/', data, tokens})
              .then(response => {
                  if (response.status >= 200 && response.status < 300) {
                      commit('setCarBidMessage', 'Ваша ставка успешно отправлена!')
                  } else {
                      return response.json()
                  }
              })
              .then(data => {
                  if (data?.message) {
                      commit('setCarBidMessage', data.message)
                  }
              })
        }
    },
    getters: {
        getDetail(state) {
            return state.detail
        },
        getCityList(state) {
            return state.city
        },
        getCountryDestination(state) {
            return state.countryDestination
        },
        getPortDestination(state) {
            return state.portDestination
        },
        getPortCanada(state) {
            return state.portCanada
        },
        getCalculateResult(state) {
            return state.calculate
        },
        getCalculatorBodyList(state) {
            return state.calculatorBodyList
        },
        getFavoriteMessage(state) {
            return state.favoriteMessage
        },
        getCarBidMessage(state) {
            return state.carBidMessage
        },
    }
}