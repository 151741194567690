<template>
  <Header class="light"></Header>
  <section class="detail">
    <div class="container">
      <div v-if="detail" class="row justify-content-center">
        <div class="col-md-6 col-12">
          <div class="detail-wrap">
            <div
              class="detail-slider"
              :class="MainSliderFullSize && 'fullscreen'"
            >
              <div
                v-if="MainSliderFullSize"
                class="close-fullscreen"
                v-on:click="MainSliderFullSize = false"
              ></div>
              <div
                v-if="MainSliderFullSize"
                class="close-fullscreen-icon"
                v-on:click="MainSliderFullSize = false"
              ></div>
              <div
                v-if="MainSliderFullSize"
                class="slider-next-btn"
                v-on:click="sliderNav('next')"
              ></div>
              <div
                v-if="MainSliderFullSize"
                class="slider-prev-btn"
                v-on:click="sliderNav('prev')"
              ></div>
              <div v-if="MainSliderFullSize" class="detail-main-slider">
                <img
                  :src="DetailMainSlide"
                  alt="Car"
                  v-on:click="MainSliderFullSize = true"
                />
              </div>
              <div class="slider" v-if="!MainSliderFullSize">
                <splide :options="sliderOptions" ref="slider">
                  <splide-slide
                    v-for="slide in detail.car_images"
                    :key="slide.id"
                  >
                    <img
                      :src="slide.image"
                      alt="slide"
                      v-on:click="MainSliderFullSize = true"
                    />
                  </splide-slide>
                </splide>
              </div>
              <Splide :options="thumbsOptions" class="custom-slider-thumb">
                <SplideSlide
                  v-for="(slide, index) in detail.car_images"
                  :key="index"
                  @click="
                    MainSliderFullSize
                      ? updateMainSlider(slide, index)
                      : onSlideClick(slide)
                  "
                >
                  <img :src="slide.image" :alt="slide" />
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="detail-name mt-2">
            <div class="detail-title-block">
              <h1 class="detail-title">
                {{ detail.brand + " " + detail.model }}
              </h1>
              <span class="detail-view"
                >Просмотров: {{ detail.view_count }}</span
              >
            </div>
            <div class="detail-price-block">
              <span class="detail-price">Цена: ${{ detail.price }}</span>
            </div>
          </div>
          <div class="detail-info pb-3">
            <h3>Технические характеристики</h3>
            <div class="row car-info">
              <div class="col-md-6 col-6">
                <i class="fuel"></i>
                <b>
                  {{
                    detail.fuel === "Premium Unleaded"
                      ? "Premium Unleaded"
                      : detail.fuel === "Regular Unleaded"
                      ? "Regular Unleaded"
                      : "-"
                  }}
                </b>
                <span>Топливо</span>
              </div>
              <div class="col-md-6 col-6">
                <i class="year"></i>
                <b>{{ detail.year ? detail.year : "-" }}</b>
                <span>Год</span>
              </div>
            </div>
            <div class="row car-info pb-2">
              <div class="col-md-6 col-6">
                <i class="mileage"></i>
                <b>{{ detail.mileage ? detail.mileage : "-" }} км</b>
                <span>Пробег</span>
              </div>
              <div class="col-md-6 col-6">
                <i class="volume"></i>
                <b>{{ detail.engine ? detail.engine : "-" }}</b>
                <span>Двигатель</span>
              </div>
            </div>
            <div class="row car-info pb-2">
              <div class="col-md-6 col-6">
                <i class="body"></i>
                <b>{{ detail.body ? detail.body : "-" }}</b>
                <span>Кузов</span>
              </div>
              <div class="col-md-6 col-6">
                <i class="transmission"></i>
                <b>{{ detail.transmission ? detail.transmission : "-" }}</b>
                <span>КПП</span>
              </div>
            </div>
            <div class="row car-info pb-2">
              <div class="col-md-6 col-6">
                <i class="drive"></i>
                <b>{{ detail.drive_unit ? detail.drive_unit : "-" }}</b>
                <span>Привод</span>
              </div>
              <div class="col-md-6 col-6">
                <i class="color"></i>
                <b>{{ detail.color ? detail.color : "-" }}</b>
                <span>Цвет</span>
              </div>
            </div>
            <h3 v-if="detail.description">Дополнительно</h3>
            <p class="detail-text pb-3" v-if="detail.description">
              {{ detail.description }}
            </p>
          </div>
          <a
            :href="'tel:' + detail.phone"
            target="_blank"
            class="custom-button block blink-animate report-button"
            >Связаться с продавцом</a
          >
        </div>
      </div>
    </div>
  </section>
  <Contacts></Contacts>
  <Footer></Footer>
</template>

<script>
import { useHead } from "@vueuse/head";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";
import Header from "@/components/Header.vue";
import Contacts from "@/components/Contacts.vue";
import Footer from "@/components/Footer.vue";

export default {
  setup() {
    useHead({
      title: "AutoCanada - Авто в наличии",
    });
  },
  data() {
    return {
      thumbsOptions: {
        rewind: true,
        fixedWidth: 100,
        fixedHeight: 65,
        isNavigation: true,
        gap: "5px",
        focus: "center",
        pagination: false,
        cover: true,
        arrows: false,
        breakpoints: {
          640: {
            fixedWidth: 66,
            fixedHeight: 38,
          },
        },
      },
      sliderOptions: {
        type: "slide", // Тип слайдера, 'slide' для горизонтального или 'fade' для плавного перехода
        rewind: true, // Возвращение к первому слайду после последнего и наоборот
        perPage: 1, // Количество слайдов для отображения одновременно
        perMove: 1, // Количество слайдов для перемещения за одно нажатие
        pagination: false, // Показывать ли пагинацию (точки или номера слайдов)
        arrows: true, // Показывать ли стрелки навигации
        autoplay: false, // Автоматическое переключение слайдов
      },
      MainSliderFullSize: false,
      DetailMainSlide: "",
      CurrentSlideIndex: 0,
    };
  },
  components: {
    Splide,
    SplideSlide,
    Header,
    Contacts,
    Footer,
  },
  created() {
    this.scrollToTop();
    if (
      String(this.$store.getters.getStockDetail.id) !==
      String(this.$router.currentRoute.value.params.id)
    ) {
      this.$store.dispatch(
        "getStockDetail",
        this.$router.currentRoute.value.params.id
      );
    }
  },
  computed: {
    detail() {
      return this.$store.getters.getStockDetail;
    },
  },
  watch: {
    detail() {
      this.DetailMainSlide = this.detail.car_images[0].image;
    },
  },
  methods: {
    scrollToCalculator() {
      document
        .getElementById("calculator")
        .scrollIntoView({ behavior: "smooth" });
    },
    scrollToTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    updateMainSlider(item, index) {
      this.CurrentSlideIndex = index;
      this.DetailMainSlide = item.image;
    },
    showModal() {
      this.$store.dispatch("showModal");
    },
    sliderNav(action) {
      if (action === "next") {
        if (this.detail.car_images[this.CurrentSlideIndex + +1] !== undefined) {
          this.CurrentSlideIndex = this.CurrentSlideIndex + +1;
          this.DetailMainSlide =
            this.detail.car_images[this.CurrentSlideIndex].image;
        } else {
          this.CurrentSlideIndex = 0;
          this.DetailMainSlide = this.detail.car_images[0].image;
        }
      } else {
        if (this.detail.car_images[this.CurrentSlideIndex - 1] !== undefined) {
          this.CurrentSlideIndex = this.CurrentSlideIndex - 1;
          this.DetailMainSlide =
            this.detail.car_images[this.CurrentSlideIndex].image;
        }
      }
    },
    onSlideClick(slide) {
      const slider = this.$refs.slider;
      const index = this.detail.car_images.findIndex((s) => s.id === slide.id);
      slider.go(index);
    },
  },
};
</script>

<style scoped>
.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: none;
}

.slider {
  height: 500px;
}

@media screen and (max-width: 720px) {
  .slider {
    height: 280px;
  }
}
</style>
