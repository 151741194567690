<template>
  <section class="header">
    <div class="container">
      <ModalRegister
          v-if="showModalRegister"
          :close="closeModal"
          :openLogin="openModalLogin"
          :openModalPasswordRecovery="openModalPasswordRecovery"
      />
      <ModalLogin
          v-if="showModalLogin"
          :close="closeModal"
          :openRegister="openModalRegister"
          :openModalPasswordRecovery="openModalPasswordRecovery"
      />
      <ModalConfirmation v-if="showModalLConfirm" :close="closeModal"/>
      <PasswordRecovery
          v-if="showModalPasswordRecovery"
          :openLogin="openModalLogin"
          :openRegister="openModalRegister"
          :close="closeModal"
      />
      <div class="menu">
        <router-link to="/" class="menu-logo"></router-link>
        <router-link to="/marks" class="menu-nav">Поиск авто</router-link>
        <router-link to="/services" class="menu-nav">Как заказать?</router-link>
        <router-link to="/stock" class="menu-nav">Авто в наличии</router-link>
        <router-link to="/calculator" class="menu-nav">Калькулятор</router-link>
        <router-link to="/auction" class="menu-nav">Аукцион</router-link>
        <router-link to="/contacts" class="menu-nav">Контакты</router-link>
        <router-link to="/data" class="menu-nav">Для клиентов</router-link>
        <div class="header-user" v-if="!getUser">
          <button @click="openModalLogin" class="personal-btn" type="button">
            Личный кабинет
          </button>
        </div>
        <router-link to="/user" class="user-nav">
          <div class="user-menu" v-if="getUser">
            <div>
              <p>{{ getUser.first_name }} {{ getUser.last_name }}</p>
              <p class="user-under-text">
              <span :class="[getUser.user_status === 'simple' ? 'standard' : 'vip']">
                {{ getUser.user_status === 'simple' ? 'Стандарт' : 'VIP' }}
              </span>
                <span>Счет <span class="user-balance">$ {{ getUser.balance }}</span></span>
              </p>
            </div>
            <div>
              <img :src="getUser.avatar || this.userDefaultAvatar" alt="Avatar">
            </div>
          </div>
        </router-link>
        <div class="mobile-menu" :class="menuStatus + this.theme === 'light' ? ' dark' : ''" v-on:click="openMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <Transition>
      <div class="mobile-menu-list" v-if="menuStatus === 'open'">
        <router-link to="/marks">Поиск авто</router-link>
        <router-link to="/about">О компании</router-link>
        <router-link to="/services">Как заказать?</router-link>
        <router-link to="/stock">Авто в наличии</router-link>
        <router-link to="/calculator">Калькулятор</router-link>
        <router-link to="/auction">Аукцион</router-link>
        <router-link to="/contacts">Контакты</router-link>
        <router-link to="/data">Для клиентов</router-link>
      </div>
    </Transition>
  </section>
</template>

<script>
import ModalRegister from './ModalRegister.vue'
import ModalLogin from './ModalLogin.vue'
import PasswordRecovery from './PasswordRecovery.vue'
import ModalConfirmation from "./ModalConfirmation.vue";
import noAvatar from '../assets/img/icons/no-avatar.jpg'

export default {
  components: {
    ModalRegister,
    ModalLogin,
    ModalConfirmation,
    PasswordRecovery
  },
  data() {
    return {
      menuStatus: '',
      showModalRegister: false,
      showModalLogin: false,
      showModalLConfirm: false,
      showModalPasswordRecovery: false,
      userDefaultAvatar: noAvatar
    }
  },
  props: ['theme'],
  computed: {
    getUser() {
      return this.$store.getters.getUser
    },
    getShowRegister() {
      return this.$store.getters.getShowRegister
    },
    getShowLogin() {
      return this.$store.getters.getShowLogin
    },
    getShowConfirm() {
      return this.$store.getters.getShowConfirm
    },
    getShowPasswordRecovery() {
      return this.$store.getters.getShowPasswordRecovery
    }
  },
  watch: {
    getUser() {
      return this.getUser?.first_name ? (
          {
            user_name: this.getUser.first_name,
            phone: this.getUser.phone,
            avatar: this.getUser.avatar,
            address: this.getUser.address,
            balance: this.getUser.balance,
            city: this.getUser.city,
            country: this.getUser.country,
            email: this.getUser.email
          }
      ) : null
    },
    getShowRegister() {
      this.showModalRegister = this.$store.getters.getShowRegister
    },
    getShowLogin() {
      this.showModalLogin = this.$store.getters.getShowLogin
    },
    getShowConfirm() {
      this.showModalLConfirm = this.$store.getters.getShowConfirm
    },
    getShowPasswordRecovery() {
      this.showModalPasswordRecovery = this.$store.getters.getShowPasswordRecovery
    }
  },
  methods: {
    openModalRegister() {
      this.$store.commit('openModalRegister', true)
    },
    openModalLogin() {
      this.$store.commit('openModalLogin', true)
    },
    openModalPasswordRecovery() {
      this.$store.commit('openModalPasswordRecovery', true)
    },
    closeModal(modal) {
      const width = window.innerWidth
      if (modal === 'register-min' && width < 820) {
        this.$store.commit('openModalRegister', false)
      } else if (modal === 'login-min' && width < 820) {
        this.$store.commit('openModalLogin', false)
      } else if (modal === 'confirm') {
        this.$store.commit('openModalConfirm', false)
      } else if (modal === 'passwordRecovery-min' && width < 820) {
        this.$store.commit('openModalPasswordRecovery', false)
      } else if (modal === 'register' && width > 820) {
        this.$store.commit('openModalRegister', false)
      } else if (modal === 'login' && width > 820) {
        this.$store.commit('openModalLogin', false)
      } else if (modal === 'passwordRecovery' && width > 820) {
        this.$store.commit('openModalPasswordRecovery', false)
      } else if (modal === 'register-all') {
        this.$store.commit('openModalRegister', false)
      } else if (modal === 'login-all') {
        this.$store.commit('openModalLogin', false)
      } else if (modal === 'passwordRecovery-all') {
        this.$store.commit('openModalPasswordRecovery', false)
      }
    },
    openMenu() {
      this.menuStatus = (this.menuStatus === '') ? 'open' : ''
    }
  },
}
</script>