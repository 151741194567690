<template>
  <div class="my-modal" @click="close('web')">
    <div class="my-modal-content" @click.stop>
      <div class="btn-modal-close" @click="close('min')"/>
      <h2>Настройки профиля</h2>
      <form @submit.prevent="savePassword">
        <div class="my-modal-rows">
          <div class="input-row">
            <label for="password">Старый пароль:</label>
            <input :type="[showPassword ? 'text' : 'password']" id="password" required v-model="profile.password" />
            <span :class="showPassword ? 'password-toggle show-password' : 'password-toggle'" @click="togglePasswordVisibility"/>
          </div>
          <div class="input-row">
            <label for="new_password">Новый пароль:</label>
            <input :type="[showPassword ? 'text' : 'password']" id="new_password" required v-model="profile.new_password" />
            <span :class="showPassword ? 'password-toggle show-password' : 'password-toggle'" @click="togglePasswordVisibility"/>
          </div>
          <div class="input-row">
            <label for="second_new_password">Повторите новый пароль:</label>
            <input :type="[showPassword ? 'text' : 'password']" id="second_new_password" required v-model="profile.second_new_password" />
            <span :class="showPassword ? 'password-toggle show-password' : 'password-toggle'" @click="togglePasswordVisibility"/>
          </div>
        </div>
        <div class="button-row">
          <button class="custom-button blink-animate block">Сохранить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profile: {
        password: '',
        new_password: '',
        second_new_password: '',
      },
      showPassword: false,
    };
  },
  props: ['close'],
  methods: {
    async savePassword() {
      const data = {...this.profile}

      if (this.profile.new_password !== this.profile.second_new_password) {
        this.$store.dispatch('notification', {message: 'Пароли не совпадают!'})
        return
      }

      await this.$store.dispatch('updatePassword', data)
      this.profile = {
        password: '',
        new_password: '',
        second_new_password: '',
      }

      if (this.$store.getters.getPasswordStatus === '') {
        this.close('all')
      }
      this.$store.commit('setPasswordStatus', '')
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  }
};
</script>

<style scoped>
.input-row {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto 10px;
  position: relative;
}

.input-row label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1E1E1E;
  opacity: 0.8;
}

.input-row input {
  width: 100%;
  padding: 15px 20px;
}

.password-toggle {
  top: 70%!important;
}

.button-row {
  max-width: 430px;
  margin: 110px auto 50px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 820px){
  .my-modal-content h2 {
    margin-top: 30px;
  }

  .input-row {
    width: 100%;
  }

  .button-row {
    margin: 30px auto 20px;
  }
}
</style>