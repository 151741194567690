export default {
    state: {
        loading: false,
        notification: null,
        notificationType: '',
        modalStatus: false
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        notification(state, payload) {
            state.notification = payload.message
            state.notificationType = (payload.type) ? payload.type : 'error'
        },
        clearNotification(state) {
            state.notification = null
        },
        setModalStatus(state, payload) {
            state.modalStatus = payload
        }
    },
    actions: {
        setLoading({commit}, payload) {
            commit('setLoading', payload)
        },
        notification({commit}, payload) {
            commit('notification', payload)
        },
        clearNotification({commit}) {
            commit('clearNotification')
        },
        showModal({commit}) {
            commit('setModalStatus', true)
        },
        closeModal({commit}) {
            commit('setModalStatus', false)
        }
    },
    getters: {
        loading(state) {
            return state.loading
        },
        notification(state) {
            return {
                message: state.notification,
                type: state.notificationType
            }
        },
        modalStatus(state) {
            return state.modalStatus
        }
    }
}