<template>
  <div class="container">
    <p class="mbank-description">Пополнить лицевой счет вы сможете с помощю элекектронного кошелька МBank</p>
    <div class="personal-account">
      <p>МЫРЗАИБРАИМОВ АЗАМАТ ШАДЫБЕКОВИЧ</p>
      <p><b>ИНН получателя:</b> 22108197800381</p>
      <p><b>Лицевой счет:</b> 1030120316711092</p>
      <p><b>Адрес банка получателя:</b> 720033, Кыргызстан, г. Бишкек, ул. Тоголок Молдо, 54а</p>
      <p><b>БИК банка получателя:</b> 103001</p>
      <p><b>ИНН банка получателя:</b> 02712199110068</p>
    </div>
    <div class="mbank-instruction">
      <h4>Для пополнения счета:</h4>
      <ol>
        <li>Зайдите в раздел “Платежи” - и выберите ”Переводы” далее необходимо выбрать тип денежных переводов.</li>
        <li>Введите номер телефона, привязанный к MBank Online в      формате 996*** или введите номер лицевого счета карты.</li>
        <li>Внесите купюру и совершите пополнение.</li>
      </ol>
    </div>
    <h4 class="mbank-screens-title">Пример оплаты:</h4>
    <div class="mbank-screens">
      <MbankImages :images="images" />
    </div>
  </div>
</template>

<script>
import MbankImages from "@/components/MbankImages.vue";
import mbank1 from '../assets/img/mbank1.jpg'
import mbank2 from '../assets/img/mbank2.jpg'
import mbank3 from '../assets/img/mbank3.jpg'
import mbank4 from '../assets/img/mbank4.jpg'
import mbank5 from '../assets/img/mbank5.jpg'

const images = [mbank1, mbank2, mbank3, mbank4, mbank5]

export default {
  data() {
    return {
      images: images
    }
  },
  components: {MbankImages}
}
</script>

<style scoped>
  .mbank-description {
    max-width: 726px;
    margin: 0 auto 60px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #1E1E1E;
  }

  .personal-account {
    max-width: 465px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: -2px -2px 5px rgba(30, 30, 30, 0.14), 2px 2px 5px rgba(30, 30, 30, 0.14);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 26px 0;
    text-align: center;
  }

  .personal-account p {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
  }

  .mbank-instruction {
    max-width: 550px;
    margin: 80px auto 0;
  }

  .mbank-instruction h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 20px;
  }

  .mbank-instruction ol {
    padding-left: 16px;
  }

  .mbank-instruction ol li {
    margin-bottom: 30px;
  }

  .mbank-screens-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    padding: 20px 0;
    margin: 0;
  }

  .mbank-screens {
    max-width: 882px;
    margin: 0 auto 260px;
  }
</style>