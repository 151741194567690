<template>
  <div class="my-login-modal" @click="close('login')">
    <div class="my-login-modal-content" @click.stop>
      <div class="btn-modal-close" @click="close('login-min')"/>
      <h2>Авторизация</h2>

      <form @submit.prevent="loginF">
        <div class="my-login-modal-rows">
          <div class="my-login-modal-row">
            <input type="text" id="login" v-model="login" required placeholder="Введите номер телефона(+***) или эл.почту"/>
          </div>

          <div class="my-login-modal-row">
            <input
                :type="[showPassword ? 'text' : 'password']"
                id="password"
                v-model="password"
                required
                placeholder="Пароль"
            />
            <span :class="showPassword ? 'password-toggle show-password' : 'password-toggle'" @click="togglePasswordVisibility">

            </span>
          </div>
        </div>

        <div class="btn-block">
          <button class="custom-button" type="submit" :disabled="!(login && password)">Войти</button>
        </div>
      </form>

      <div class="another_links">
        <p>Нету профиля? <span @click="[openRegister(), close('login-all')]">Регистрация</span></p>
        <p>Забыли пароль? <span @click="[openModalPasswordRecovery(), close('login-all')]">Восстановить</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: '',
      password: '',
      showPassword: false,
    };
  },
  props: ['close', 'openRegister', 'openModalPasswordRecovery'],
  methods: {
    async loginF() {
      const data = {
        login: this.login,
        password: this.password,
      }

      await this.$store.dispatch('getUser', data)
      if (this.$store.getters.getUser) {
        this.$router.push('/user')
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  }
};
</script>

<style>
.my-login-modal {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-login-modal-content {
  position: relative;
  background-color: #fff;
  padding: 60px 150px 150px;
  border-radius: 10px;
  max-width: 980px;
}

.my-login-modal h2 {
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 50px;
  line-height: 62px;
  color: #1E1E1E;
  text-align: center;
}

.my-login-modal-rows {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.my-login-modal-row {
  width: 430px;
  margin: 0 auto;
  position: relative;
}

.my-login-modal label {
  display: block;
  margin-top: 10px;
}

.my-login-modal input[type="text"],
.my-login-modal input[type="password"] {
  width: 100%;
  padding: 21px 17px 18px;
  margin-top: 5px;
  background: rgba(30, 30, 30, 0.05);
  border-radius: 2px;
  border: none;
}

.my-login-modal input::placeholder {
  font-size: 16px;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  background: url("../assets/img/icons/hide-password.png");
}

.my-login-modal .btn-block {
  width: max-content;
  margin: 40px auto 0;
}

.my-login-modal button {
  width: 430px;
  font-size: 18px;
  line-height: 22px;
}

.my-login-modal button:disabled {
  pointer-events: none;
  background: #a1a0a0;
}

@media screen and (max-width: 820px){
  .my-login-modal h2 {
    margin: 0 0 10px;
    font-size: 24px!important;
    line-height: 28px!important;
  }

  .my-login-modal-content {
    width: 90%;
    padding: 20px 30px 70px!important;
  }

  .my-login-modal-row {
    width: 260px!important;
  }

  .my-login-modal input[type="text"],
  .my-login-modal input[type="password"] {
    font-size: 12px;
    padding: 8px 6px 6px;
  }

  .my-login-modal input::placeholder {
    font-size: 12px;
  }

  .my-login-modal .btn-block {
    width: 80%;
  }

  .my-login-modal button {
    width: 100%;
    height: 30px;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>