<template>
    <section class="main d-flex align-items-center">
        <div class="main-line"></div>
        <div class="main-cloud"></div>
        <div class="flag-canada"></div>
        <div class="flag-united-state"></div>
        <div class="social-icons" :class="(socialIconStatus) && 'active'">
            <a href="https://wa.me/+996554888484" target="_blank" class="whatsapp"></a>
            <a href="https://www.facebook.com/autocanada_kg" target="_blank" class="facebook"></a>
            <a href="https://www.instagram.com/autocanada.kg/" target="_blank" class="instagram"></a>
            <a href="https://t.me/Akka_Kebnekaise" target="_blank" class="telegram"></a>
        </div>
        <div class="social-icon-menu" v-on:click="showSocialIcons"></div>
        <div class="main-slider">
            <template v-for="(slider, index) in sliders" :key="index++">
                <div class="main-slide" :class="currentSlide !== index && 'd-none'">
                    <img :src="slider.image" :alt="slider.header" v-if="slider.image">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h1>{{ slider.header }}</h1>
                                <p>{{ slider.description }}</p>
                                <router-link :to="slider.url" class="main-btn blink-animate" v-if="slider.url">Регистрация</router-link>
                                <a class="main-btn blink-animate" v-on:click="showModal" v-else>Регистрация</a>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="slider-thumbnails">
                <template v-for="(slider, index) in sliders" :key="index++">
                    <div class="slider-thumb" :class="currentSlide === index && 'active'" v-on:click="selectSlider(index)">
                        <img :src="slider.icon ? slider.icon : slider.image" :alt="slider.header" v-if="slider.image || slider.icon">
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            currentSlide: 1,
            silderStatus: true,
            socialIconStatus: false,
        }
    },
    computed: {
        sliders() {
            return (this.$store.getters.getMainSlider)
            ? this.$store.getters.getMainSlider
            : this.$store.dispatch('getMainSlider')
        }
    },
    watch: {
        sliders() {
            const self = this
            setInterval(function() {
                if(self.silderStatus) {
                    if(self.currentSlide >= self.sliders.length) {
                        self.currentSlide = 1
                    } else {
                        self.currentSlide++
                    }
                }
            }, 4500)
        }
    },
    methods: {
        showSocialIcons() {
            this.socialIconStatus = !this.socialIconStatus && true
        },
        showModal() {
          this.$store.commit('openModalRegister', true)
        },
        selectSlider(slider) {
            this.silderStatus = false
            this.currentSlide = slider
        }
    }
}
</script>