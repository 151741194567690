<template>
  <div class="my-modal" @click="close('web')">
    <div class="my-modal-content" @click.stop>
      <div class="btn-modal-close" @click="close('min')"/>
      <h2>Настройки профиля</h2>
      <form @submit.prevent="saveProfile">
        <div class="my-modal-rows">
          <div class="input-row">
            <label for="first_name">Имя:</label>
            <input type="text" id="first_name" required v-model="profile.first_name" />
          </div>
          <div class="input-row">
            <label for="last_name">Фамилия:</label>
            <input type="text" id="last_name" required v-model="profile.last_name" />
          </div>
          <div class="input-row">
            <label for="city">Город:</label>
            <input type="text" id="city" v-model="profile.city" />
          </div>
          <div class="input-row">
            <label for="phoneNumber">Номер телефона:</label>
            <input type="tel" id="phoneNumber" v-model="profile.phone" />
          </div>
          <div class="input-row">
            <label for="country">Страна:</label>
            <input type="text" id="country" v-model="profile.country" />
          </div>
          <div class="input-row">
            <label for="email">Почта:</label>
            <input type="email" id="email" required v-model="profile.email" />
          </div>
          <div class="input-row">
            <label for="postalCode">Почтовый индекс:</label>
            <input type="text" id="postalCode" v-model="profile.postcode" />
          </div>
          <div class="input-row">
            <label for="address">Адрес:</label>
            <input type="text" id="address" v-model="profile.address" />
          </div>
        </div>
        <div class="button-row">
          <button class="custom-button blink-animate block">Сохранить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profile: {
        first_name: this.detail.first_name || '',
        last_name: this.detail.last_name || '',
        city: this.detail.city || '',
        phone: this.detail.phone || '',
        country: this.detail.country || '',
        email: this.detail.email || '',
        postcode: this.detail.postcode || '',
        address: this.detail.address || '',
      }
    };
  },
  props: ['close', 'detail'],
  methods: {
    saveProfile() {
      const data = {...this.profile}

      this.$store.dispatch('updateUser', data)
    }
  }
};
</script>

<style scoped>
.input-row {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 10px;
}

.input-row label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1E1E1E;
  opacity: 0.8;
}

.input-row input {
  width: 100%;
  padding: 15px 20px;
}

.button-row {
  max-width: 430px;
  margin: 110px auto 50px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1280px){
  .button-row {
    margin: 30px auto 20px;
  }
}

@media screen and (max-width: 820px){
  .my-modal-content {
     padding: 15px 30px;
  }

  .btn-modal-close {
    right: 15px;
    top: 20px;
  }

  .my-modal-content h2 {
    margin-top: 0;
  }

  .my-modal-rows {
    margin-bottom: 20px;
  }

  .input-row {
    width: 100%;
    margin: 0;
  }

  .input-row label {
    font-size: 10px;
    line-height: 14px;
  }

  .input-row input {
    font-size: 10px;
    width: 100%;
    padding: 6px 10px;
  }

  .button-row {
    margin: 10px auto;
  }
}
</style>