<template>
  <Header />
  <section class="page-header for-client-bg">
    <div class="container">
      <h1>Посмотреть фото</h1>
    </div>
  </section>
  <div class="container for-client-con">
    <div class="for-client-left">
      <form @submit.prevent="onSubmit">
        <div class="input-block">
          <input type="text" required placeholder="VIN" v-model="vin">
          <button></button>
        </div>
      </form>
      <div class="auto-information">
        <div :class="active === 1 ? 'click-point active' : 'click-point'" v-on:click="nextImages('auction', 1)">
          <p class="auto-information-country">Канада / США</p>
          <div class="auto-information-img-block">
            <img src="../assets/img/icons/for-client-icon1.png" alt="Icon">
          </div>
          <p class="auto-information-description">Фото <br> с аукциона</p>
        </div>
        <div :class="active === 2 ? 'click-point active' : 'click-point'" v-on:click="nextImages('port', 2)">
          <p class="auto-information-country">Канада / США</p>
          <div class="auto-information-img-block">
            <img src="../assets/img/icons/for-client-icon2.png" alt="Icon">
          </div>
          <p class="auto-information-description">Фото <br> перед погрузкой</p>
        </div>
        <div :class="active === 3 ? 'click-point active' : 'click-point'" v-on:click="nextImages('lithuania', 3)">
          <p class="auto-information-country">Литва / Грузия</p>
          <div class="auto-information-img-block">
            <img src="../assets/img/icons/for-client-icon3.png" alt="Icon">
          </div>
          <p class="auto-information-description">Фото <br> после выгрузки</p>
        </div>
        <div :class="active === 4 ? 'click-point active' : 'click-point'" v-on:click="nextImages('kg', 4)">
          <p class="auto-information-country">Кыргызстан</p>
          <div class="auto-information-img-block">
            <img src="../assets/img/icons/for-client-kg.png" alt="Icon">
          </div>
          <p class="auto-information-description">Фото <br> после доставки</p>
        </div>
      </div>
    </div>

    <div v-if="this.images?.length" class="for-client-right">
      <div class="detail-slider" :class="MainSliderFullSize && 'fullscreen'">
        <div v-if="MainSliderFullSize" class="close-fullscreen" v-on:click="MainSliderFullSize = false"></div>
        <div v-if="MainSliderFullSize" class="close-fullscreen-icon" v-on:click="MainSliderFullSize = false"></div>
        <div v-if="MainSliderFullSize" class="slider-next-btn" v-on:click="sliderNav('next')"></div>
        <div v-if="MainSliderFullSize" class="slider-prev-btn" v-on:click="sliderNav('prev')"></div>
        <div v-if="MainSliderFullSize" class="detail-main-slider">
          <img :src="DetailMainSlide" alt="Car" v-on:click="MainSliderFullSize = true">
        </div>
        <div class="slider" v-if="!MainSliderFullSize">
          <splide :options="sliderOptions" ref="slider">
            <splide-slide v-for="slide in images" :key="slide.id">
              <img :src="slide.image" alt="slide" v-on:click="MainSliderFullSize = true">
            </splide-slide>
          </splide>
        </div>
        <Splide :options="thumbsOptions" class="custom-slider-thumb">
          <SplideSlide v-for="(slide, index) in images" :key="index" @click="MainSliderFullSize ? updateMainSlider(slide, index) : onSlideClick(slide)">
            <img :src="slide.image" :alt="slide">
          </SplideSlide>
        </Splide>
        
        <!-- Download All Photos Button -->
        <div class="download-all-container">
          <button 
            v-if="images && images.length > 0" 
            class="download-all-btn" 
            @click="downloadAllPhotos"
          >
            <span class="download-icon"></span>
            Скачать все фото
          </button>
        </div>
      </div>
    </div>
    <h2 v-if="vinCar && !images?.length" style="text-align: center">Фото ещё не добавлено</h2>
  </div>
  <Contacts />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Contacts from "@/components/Contacts.vue";
import {useHead} from "@vueuse/head";
import {Splide, SplideSlide} from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'

export default {
  setup() {
    useHead({
      title: 'Для клиентов - AutoCanada',
    })
  },
  data() {
    return {
      vin: '',
      vinCar: null,
      images: '',
      MainSliderFullSize: false,
      DetailMainSlide: '',
      active: '',
      CurrentSlideIndex: 0,
      thumbsOptions: {
        rewind: true,
        fixedWidth: 100,
        fixedHeight: 65,
        isNavigation: true,
        gap: '5px',
        focus: 'center',
        pagination: false,
        cover: true,
        arrows: false,
        breakpoints: {
          640: {
            fixedWidth: 66,
            fixedHeight: 38,
          },
        },
      },
      sliderOptions: {
        type: 'slide', // Тип слайдера, 'slide' для горизонтального или 'fade' для плавного перехода
        rewind: true, // Возвращение к первому слайду после последнего и наоборот
        perPage: 1, // Количество слайдов для отображения одновременно
        perMove: 1, // Количество слайдов для перемещения за одно нажатие
        pagination: false, // Показывать ли пагинацию (точки или номера слайдов)
        arrows: true, // Показывать ли стрелки навигации
        autoplay: false, // Автоматическое переключение слайдов
      },
      stageNames: {
        auction: 'Аукцион',
        port: 'Перед_погрузкой',
        lithuania: 'После_выгрузки',
        kg: 'После_доставки'
      }
    }
  },
  components: {
    Header, Footer, Contacts, Splide, SplideSlide
  },
  methods: {
    async onSubmit() {
      await this.$store.dispatch('getCarByVin', this.vin)

      this.vinCar = this.$store.getters.getVinCar
      if (this.vinCar) {
        this.images = this.vinCar.auction
        this.active = 1
        if (this.images) {
          this.DetailMainSlide = this.images[0]?.image
          this.CurrentSlideIndex = 0
        }
      }
    },
    sliderNav(action) {
      if (action === 'next') {
        if (this.images[this.CurrentSlideIndex + 1] !== undefined) {
          this.CurrentSlideIndex = this.CurrentSlideIndex + 1
          this.DetailMainSlide = this.images[this.CurrentSlideIndex].image
        } else {
          this.CurrentSlideIndex = 0
          this.DetailMainSlide = this.images[0].image
        }
      } else {
        if (this.images[this.CurrentSlideIndex - 1] !== undefined) {
          this.CurrentSlideIndex = this.CurrentSlideIndex - 1
          this.DetailMainSlide = this.images[this.CurrentSlideIndex].image
        }
      }
    },
    updateMainSlider(item, index) {
      this.CurrentSlideIndex = index
      this.DetailMainSlide = item.image
    },
    nextImages(value, active) {
      if (!this.vinCar) {
        this.$store.commit('notification', {message: 'Сначала введите VIN!'})
        return
      }
      this.active = active
      this.images = this.vinCar[value]
      this.DetailMainSlide = this.images[0]?.image
      this.CurrentSlideIndex = 0
    },
    onSlideClick(slide) {
      // Получаем ссылку на экземпляр слайдера
      const slider = this.$refs.slider;

      // Находим индекс выбранного слайда
      const index = this.images.findIndex((s) => s.id === slide.id);

      // Переключаем слайдер на соответствующий слайд
      slider.go(index);
    },
    // New method to download all photos
    async downloadAllPhotos() {
      if (!this.images || this.images.length === 0) {
        this.$store.commit('notification', {message: 'Нет фотографий для скачивания!'})
        return
      }
      
      // Get current stage name for the zip file name
      const currentStage = Object.keys(this.stageNames).find(key => this.vinCar[key] === this.images)
      const stageName = this.stageNames[currentStage] || 'Фото'
      
      try {
        // Create a loading notification
        this.$store.commit('notification', {message: 'Подготовка фотографий для скачивания...'})
        
        // Use JSZip library to create a zip file
        // Note: You need to add JSZip to your project: npm install jszip
        const JSZip = (await import('jszip')).default
        const zip = new JSZip()
        
        // Add each image to the zip file
        const imagePromises = this.images.map(async (image, index) => {
          try {
            // Fetch the image
            const response = await fetch(image.image)
            if (!response.ok) throw new Error(`Failed to fetch image ${index + 1}`)
            
            const blob = await response.blob()
            
            // Get file extension from blob type or default to jpg
            const fileExt = blob.type.split('/')[1] || 'jpg'
            
            // Add to zip with a numbered filename
            zip.file(`${stageName}_${index + 1}.${fileExt}`, blob)
          } catch (error) {
            console.error(`Error processing image ${index + 1}:`, error)
          }
        })
        
        // Wait for all images to be processed
        await Promise.all(imagePromises)
        
        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: 'blob' })
        
        // Create a download link and trigger download
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(zipBlob)
        downloadLink.download = `${this.vin}_${stageName}.zip`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        
        // Success notification
        this.$store.commit('notification', {message: 'Фотографии успешно скачаны!'})
      } catch (error) {
        console.error('Error downloading photos:', error)
        this.$store.commit('notification', {message: 'Ошибка при скачивании фотографий!'})
      }
    }
  }
}
</script>

<style scoped>
  .slider {
    height: 500px;
  }

  .for-client-bg {
    background: url('../assets/img/for-client-promo.jpg') no-repeat center / cover!important;
    padding: 150px 0 128px;
  }

  .for-client-con {
    display: flex;
    margin: 50px auto 150px;
  }

  .for-client-left {
    width: 56%;
  }

  .for-client-left form {
    max-width: 470px;
  }

  .input-block {
    display: flex;
    padding: 8px;
    background: rgba(30, 30, 30, 0.05);
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-bottom: 30px;
  }

  .input-block input {
    width: 100%;
    border: none;
    background: transparent;
    font-size: 18px;
    padding: 0 10px;
  }

  .input-block button {
    width: 70px;
    height: 50px;
    background: #115B7D url("../assets/img/icons/for-client-btn.png") no-repeat 50%;
    border: none;
    border-radius: 3px;
  }

  .auto-information {
    display: flex;
    gap: 10px;
    margin-top: 80px;
    text-align: center;
    font-size: 15px;
  }

  .click-point {
    cursor: pointer;
    padding: 10px 10px 0;
  }

  .click-point:active {
    transform: scale(1.1);
  }

  .click-point.active {
    border: 1px solid #A02C12;
  }

  .auto-information-description {
    font-size: 16px;
    font-weight: 600;
  }

  .auto-information div:first-child .auto-information-img-block {
    background: #115B7D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .auto-information div:first-child .auto-information-img-block img {
    width: 50%;
  }

  .auto-information-img-block {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0 auto 15px;
  }

  .auto-information-img-block img {
    width: 100%;
  }

  .for-client-right {
    flex-grow: 2;
    width: 40%;
  }

  .for-client-right .detail-slider.fullscreen {
    padding: 10px 14%;
  }

  .for-client-right .detail-main-slider {
    height: auto!important;
  }
  
  /* Download All Button Styles */
  .download-all-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  
  .download-all-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #115B7D;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .download-all-btn:hover {
    background: #0d4a66;
  }
  
  .download-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'/%3E%3Cpolyline points='7 10 12 15 17 10'/%3E%3Cline x1='12' y1='15' x2='12' y2='3'/%3E%3C/svg%3E") no-repeat center / contain;
  }

  @media screen and (max-width: 960px){
    .for-client-con {
      flex-direction: column;
    }

    .for-client-left {
      width: 100%;
      margin-bottom: 30px;
    }

    .for-client-right {
      width: 100%;
    }

    .for-client-left form {
      width: max-content;
      margin: 0 auto;
    }

    .input-block {
      padding: 4px;
      font-size: 12px;
    }

    .input-block input {
      font-size: 14px;
    }

    .input-block button {
      width: 50px;
      height: 40px;
    }

    .auto-information-img-block {
      width: 60px;
      height: 60px;
      margin: 0 auto 12px;
    }

    .auto-information-country {
      font-size: 14px;
    }

    .auto-information-description {
      font-size: 12px;
    }

    .auto-information {
      flex-wrap: wrap;
    }

    .click-point {
      width: 45%;
    }
    
    .download-all-btn {
      font-size: 14px;
      padding: 8px 12px;
    }
  }

  @media screen and (max-width: 720px){
    .slider {
      height: 280px;
    }
  }
</style>