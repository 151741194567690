<template>
    <Transition name="fade">
        <div class="notification" :class="notification.type" v-if="notification.message" v-on:click="closeNotification">{{ notification.message }}</div>
    </Transition>
</template>

<script>
let notificationTimer = null; // Общий таймер для всех вызовов уведомлений

export default {
  data() {
    return {
      notificationShow: false,
      notificationClass: '',
      notificationMessage: ''
    }
  },
  computed: {
    notification() {
      return this.$store.getters.notification
    }
  },
  watch: {
    notification() {
      clearTimeout(notificationTimer); // Сбрасываем таймер при каждом новом вызове уведомления

      if (this.notification.message) {
        notificationTimer = setTimeout(() => {
          this.$store.dispatch('clearNotification');
        }, 4000);
      }
    }
  },
  methods: {
    closeNotification() {
      clearTimeout(notificationTimer); // Сбрасываем таймер при закрытии уведомления
      this.$store.dispatch('clearNotification');
    }
  }
}
</script>