<template>
  <div class="lazy-loader fixed" v-if="loading">
      <div class="loader"></div>
  </div>
  <router-view></router-view>
  <Notification></Notification>
  <Feedback></Feedback>
</template>

<script>

import { useHead } from '@vueuse/head'
import Notification from './components/Notification'
import Feedback from './components/Feedback'

export default {
  setup() {
    useHead({
      title: 'AutoCanada - заказать авто из Канады и США',
      meta: [
        {'http-equiv': 'Content-Security-Policy', content: 'upgrade-insecure-requests'}
      ],
      link: [
        {rel: 'icon', href: require(`./assets/img/icons/favicon.png`)}
      ]
    })
  },
  components: {
    Notification,
    Feedback
  },
  computed: {
    loading() {
        return this.$store.getters.loading
    }
  },
  mounted() {
    this.$store.dispatch('getUserDetail', 'user')
  },
  watch: {
    $route: {
        immediate: true,
        handler() {
          // document.title = 'AutoCanada - заказать авто из Канады и США'
        }
    },
  }
}
</script>
