<template>
  <div class="container" v-if="userDetail">
    <div class="profile-balance">
      <p class="your-balance">Ваш счет: <span>$ {{userDetail.balance}}</span></p>
      <p class="profile-info">Максимальная сумма вашей ставки не может превышать ${{userDetail.balance * 10}}</p>
      <button class="personal-btn" @click="click(3)">Пополнить баланс</button>
    </div>
    <div class="user-detail">
      <div class="user-account">
        <h4>Персональный аккаунт</h4>
        <div class="user-menu profile">
          <div>
            <p class="user-name">{{ userDetail.first_name }} {{ userDetail.last_name }}</p>
            <p class="user-leave" @click="clearUser">Выйти из учетной записи</p>
          </div>
          <div>
            <img :src="userDetail.avatar || this.userDefaultAvatar" alt="Avatar">
          </div>
        </div>
        <ChangeUserDetail v-if="changeUserDetail" :detail="userDetail" :close="showChangeDetail" />
        <ModalChangePassword v-if="changeUserPassword" :close="showChangePassword" />
        <div class="profile-buttons">
          <div class="profile-button" @click="showChangeDetail('all')">
            <div>
              <img src="../assets/img/icons/edit.png" alt="edit">
            </div>
            <p>Редактировать личные данные</p>
          </div>
          <div class="profile-button" @click="showChangePassword('all')">
            <div>
              <img src="../assets/img/icons/key.png" alt="key">
            </div>
            <p>Сменить пароль</p>
          </div>
          <div @click="activateInput" class="profile-button">
            <div>
              <img src="../assets/img/icons/photo.png" alt="photo">
            </div>
            <input @change="onFileChange" name="avatar" type="file" class="avatar" ref="inputRef" />
            <p>Сменить фото профиля</p>
          </div>
        </div>
      </div>
      <div class="user-data">
        <h4>Персональные данные</h4>
        <div class="user-data-block">
          <div>
            <p>телефон:</p>
            <p>{{ userDetail.phone || 'Не указан' }}</p>
          </div>
          <div>
            <p>Email:</p>
            <p>{{ userDetail.email || 'Не указан' }}</p>
          </div>
          <div>
            <p>Адрес:</p>
            <p>{{ userDetail.address || 'Неопределено' }}</p>
          </div>
          <div>
            <p>Город:</p>
            <p>{{ userDetail.city || 'Неопределено' }}</p>
          </div>
          <div>
            <p>Страна:</p>
            <p>{{ userDetail.country || 'Неопределено' }}</p>
          </div>
          <div>
            <p>Почтовый индекс::</p>
            <p>{{ userDetail.postcode || 'Неопределено' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import ModalChangePassword from "@/components/ModalChangePassword.vue";
import ChangeUserDetail from "@/components/ModalChangeUserDetail.vue";
import noAvatar from "@/assets/img/icons/no-avatar.jpg";

export default {
  data() {
    return {
      active: 1,
      userDefaultAvatar: noAvatar,
      changeUserDetail: false,
      changeUserPassword: false,
      avatar: '',
      maxSum: 0,
    }
  },
  components: {
    ChangeUserDetail, ModalChangePassword
  },
  props: ['click'],
  computed: {
    userDetail() {
      return this.$store.getters.getUser
    }
  },
  watch: {
    userDetail() {
      return this.$store.getters.getUser
    }
  },
  methods: {
    checkBlock(value) {
      this.active = value
    },
    clearUser() {
      this.$store.commit('clearUser')
      location.reload()
    },
    showChangeDetail(size) {
      const width = window.innerWidth

      if (width < 820 && size === 'min') {
        this.changeUserDetail = !this.changeUserDetail
      } else if (width >= 820 && size === 'web') {
        this.changeUserDetail = !this.changeUserDetail
      } else if (size === 'all') {
        this.changeUserDetail = !this.changeUserDetail
      }
    },
    showChangePassword(size) {
      const width = window.innerWidth

      if (width < 820 && size === 'min') {
        this.changeUserPassword = !this.changeUserPassword
      } else if (width >= 820 && size === 'web') {
        this.changeUserPassword = !this.changeUserPassword
      } else if (size === 'all') {
        this.changeUserPassword = !this.changeUserPassword
      }
    },
    onFileChange(e) {
      if (e.target.files[0]) {
        this.avatar = e.target.files[0]
      } else {
        this.avatar = ''
      }

      this.$store.dispatch('updateUser', {avatar: this.avatar})
    }
  },
  setup() {
    const inputRef = ref(null);

    const activateInput = () => {
      inputRef.value.click();
    };

    return {
      inputRef,
      activateInput,
    };
  },
}
</script>

<style>
.profile-balance {
  display: flex;
  justify-content: right;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
  font-size: 24px;
  font-weight: 500;
}

.profile-balance p span {
  color: #A02C12;
}

.your-balance {
  margin-left: 90px;
}

.profile-info {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #A02C12;
  max-width: 320px;
  margin-left: 30px;
  background: rgba(160, 44, 18, 0.1) url("../assets/img/icons/warning.png") no-repeat 10px 50%;
  border-radius: 3px;
  padding: 5px 8px 5px 50px;
}

.profile-balance button {
  margin-left: 60px;
  font-weight: 400;
}

.user-detail {
  display: flex;
  justify-content: space-between;
  padding: 30px 40px;
  box-shadow: -2px -2px 5px rgba(30, 30, 30, 0.14), 2px 2px 5px rgba(30, 30, 30, 0.14);
  border-radius: 5px;
  margin-bottom: 255px;
}

.user-detail h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  margin: 0 0 50px 0;
}

.user-account {
  width: 360px;
}

.user-menu.profile {
  flex-direction: row-reverse;
  justify-content: left;
  padding-bottom: 16px;
  margin: 0 0 16px 0;
  border-bottom: 2px solid #E7EAEE;
}

.user-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
}

.user-leave {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #A02C12;
}

.profile-buttons {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.profile-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.profile-button div {
  cursor: pointer;
  background: #A02C12;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.profile-button img {
  width: 16px;
  height: 16px;
}

.profile-button p {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
}

.avatar {
  display: none;
}

.user-data {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
}

.user-data-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 60px;
}

.user-data-block div {
  width: max-content;
  padding: 5px 0 0 45px;
}

.user-data-block div:nth-child(1) {
  background: url("../assets/img/icons/phone.png") no-repeat 5px 10%;
}

.user-data-block div:nth-child(2) {
  background: url("../assets/img/icons/mail.png") no-repeat 5px 10%;
}

.user-data-block div:nth-child(3) {
  background: url("../assets/img/icons/map-location.png") no-repeat 5px 10%;
}

.user-data-block div:nth-child(4) {
  background: url("../assets/img/icons/map-location.png") no-repeat 5px 10%;
}

.user-data-block div:nth-child(5) {
  background: url("../assets/img/icons/map-location.png") no-repeat 5px 10%;
}

.user-data-block div:nth-child(6) {
  background: url("../assets/img/icons/mail.png") no-repeat 5px 10%;
}

.user-data-block div p:first-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 185%;
  margin-bottom: 10px;
}

.user-data-block div p:last-child {
  width: max-content;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}

@media screen and (max-width: 820px){
  .user-detail {
    flex-direction: column;
    gap: 50px;
    margin-bottom: 60px;
  }

  .user-account {
    width: 280px;
  }

  .user-data-block {
    row-gap: 10px;
  }
}
</style>