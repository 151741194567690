<template>
    <Header></Header>
    <section class="page-header contacts-bg">
        <div class="container">
            <h1>Наши контакты</h1>
        </div>
    </section>
    <section class="contacts-wrap">
        <div class="container">
            <h1 class="title">Контактная информация</h1>
            <p class="contacts-text">Если вы заинтересованы в сотрудничестве с нами, напишите нам.</p>
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="contact-info phone">
                        <b class="contact-title">Телефон</b>
                        <a href="tel:+996554888484">+996 (554) 888 484</a>
                        <a href="tel:+996550926904">+996 (704) 888 483</a>
                        <a href="tel:+14316888484">+1 431 688 84 84</a>
                    </div>
                    <div class="contact-info email">
                        <b class="contact-title">Электронная почта</b>
                        <a href="mailto:autocanadakg@gmail.com">autocanadakg@gmail.com</a>
                    </div>
                    <div class="contact-info schudle">
                        <b class="contact-title">График работы</b>
                        <span>Пн-Пт <b>08:00-19:00</b></span>
                        <span>Сб <b>08:00-16:00</b></span>
                        <span>Вс <b>Выходной</b></span>
                    </div>
                    <div class="contact-info address">
                        <b class="contact-title">Наш адрес</b>
                        <a href="https://go.2gis.com/p8ogd" target="_blank">Бишкек, ул. Суюмбаева 43 офис 4</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Contacts></Contacts>
    <Footer></Footer>
</template>

<script>
import { useHead } from '@vueuse/head'
import Header from '@/components/Header.vue'
import Contacts from '@/components/Contacts.vue'
import Footer from '@/components/Footer.vue'

export default {
    setup() {
        useHead({
            title: 'Контакты - AutoCanada',
        })
    },
    components: {
        Header,
        Contacts,
        Footer
    }
}
</script>