<template>
  <Header></Header>
  <section class="page-header about-bg">
    <div class="container">
      <h1>О компании</h1>
    </div>
  </section>
  <section class="about-features">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <i class="about-feature-icon shield"></i>
          <p class="about-feature-text">
            Quis fringilla convallis et vitae volutpat at porttitor.
          </p>
        </div>
        <div class="col-md-3">
          <i class="about-feature-icon id-card"></i>
          <p class="about-feature-text">
            Quis fringilla convallis et vitae volutpat at porttitor.
          </p>
        </div>
        <div class="col-md-3">
          <i class="about-feature-icon car-key"></i>
          <p class="about-feature-text">
            Quis fringilla convallis et vitae volutpat at porttitor.
          </p>
        </div>
        <div class="col-md-3">
          <i class="about-feature-icon tow-truck"></i>
          <p class="about-feature-text">
            Quis fringilla convallis et vitae volutpat at porttitor.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="about-v2">
    <div class="container">
      <div class="row">
        <div class="col-md-6 d-flex justify-content-center">
          <img src="../assets/img/about-v2-image.png" class="about-image" />
          <div class="our-experience d-none">
            <span>More Than</span>
            <h1>15</h1>
            <b class="mb-2">Years of</b>
            <b>Experience</b>
          </div>
        </div>
        <div class="col-md-6 about-text-wrap">
          <h1 class="title">О компании</h1>
          <p class="about-text">
            <b>Auto Canada KG</b> — официальный представитель канадской компании
            Alabel Auto Export (Канадская лицензия экспортера №5696380). Alabel
            Auto Export занимается экспортом подержанных и новых
            автотранспортных средств и мототехники из Канады по всему миру.
          </p>

          <p class="about-text">
            На нашем сайте представлен широкий выбор автомобилей, выставленных
            на аукционах
            <a class="link" target="_blank" href="https://www.copart.com/"
              >Copart.com</a
            >,<a class="link" target="_blank" href="https://www.iaai.com/"
              >Iaai.com</a
            >,
            <a class="link" target="_blank" href="https://www.copart.ca/"
              >Copart.ca</a
            >,
            <a class="link" target="_blank" href="https://impactauto.ca/"
              >Impactauto.ca</a
            >,
            <a class="link" target="_blank" href="https://www.adesa.ca/"
              >Adesa.ca</a
            >,
            <a
              class="link"
              target="_blank"
              href="https://site.manheim.com/en/locations.html"
              >Manheim.com</a
            >. Помимо этого, мы закупаем автомобили у официальных дилеров и
            частных продавцов по всей Канаде, чтобы предложить вам еще больше
            вариантов..
          </p>

          <p class="about-text">
            Мы сотрудничаем только с надежными и проверенными партнерами,
            гарантируя высокое качество услуг. Свяжитесь с нами уже сегодня,
            чтобы получить подробную информацию и помощь в выборе автомобиля,
            который наилучшим образом соответствует вашим требованиям и бюджету.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="trust-us">
    <div class="container">
      <h1 class="title light">Почему нам доверяют?</h1>
      <div class="row">
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 shield"></i>
            <span class="about-feature-name">Гарантия</span>
            <p class="about-feature-text-v2">
              Quis fringilla convallis et vitae volutpat at porttitor. Est
              tincidunt massa aliquam sed enim rhoncus.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 quality"></i>
            <span class="about-feature-name">Качество</span>
            <p class="about-feature-text-v2">
              Quis fringilla convallis et vitae volutpat at porttitor. Est
              tincidunt massa aliquam sed enim rhoncus.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 clock2"></i>
            <span class="about-feature-name">Cкорость</span>
            <p class="about-feature-text-v2">
              Quis fringilla convallis et vitae volutpat at porttitor. Est
              tincidunt massa aliquam sed enim rhoncus.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 experience"></i>
            <span class="about-feature-name">Опыт</span>
            <p class="about-feature-text-v2">
              Quis fringilla convallis et vitae volutpat at porttitor. Est
              tincidunt massa aliquam sed enim rhoncus.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 service"></i>
            <span class="about-feature-name">Сервис</span>
            <p class="about-feature-text-v2">
              Quis fringilla convallis et vitae volutpat at porttitor. Est
              tincidunt massa aliquam sed enim rhoncus.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="about-feature-wrap">
            <i class="about-feature-icon-v2 economy"></i>
            <span class="about-feature-name">Экономия</span>
            <p class="about-feature-text-v2">
              Quis fringilla convallis et vitae volutpat at porttitor. Est
              tincidunt massa aliquam sed enim rhoncus.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="about-us-v2">
    <div class="container">
      <div class="about-us-v2-title">
        Мы занимаемся подбором и поставкой подержанных автомашин из
        <span>США и Канады в Кыргызстан.</span>
      </div>
      <img src="../assets/img/about-us-v2.png" class="about-us-img-v2" />
    </div>
  </section>
  <Partners></Partners>
  <Reviews></Reviews>
  <Footer class="border-top"></Footer>
</template>

<script>
import { useHead } from "@vueuse/head";
import Header from "@/components/Header.vue";
import Partners from "@/components/Partners.vue";
import Reviews from "@/components/Reviews.vue";
import Footer from "@/components/Footer.vue";

export default {
  setup() {
    useHead({
      title: "О компании - AutoCanada",
    });
  },
  created() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
  components: {
    Header,
    Partners,
    Reviews,
    Footer,
  },
};
</script>

<style>
.link {
  text-decoration: none;
  color: #000;
  font-weight: 500;
}
</style>
