<template>
  <div class="footer">
    <ModalPrivacyPolicy v-if="privacyPolicy" :close="openInModal" />
    <ModalTermsPolicy v-if="privacyTerms" :close="openInModal"/>
    <ModalRefundPolicy v-if="privacyRefund" :close="openInModal"/>
    <div class="container d-flex align-items-center flex-wrap">
      <a href="#" class="footer-logo mb-3 mb-xxl-0">All Rights Recerved 2023</a>
      <div class="footer-text">
        <p class="d-flex align-items-center flex-wrap m-0">
          <span class="" @click="openInModal('policy')">Политики конфиденциальности</span>
          <span class="mx-0 mx-sm-5" @click="openInModal('refund')">Пользовательское соглашение</span>
          <span @click="openInModal('terms')">Политика возврата денежных средств</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  import ModalPrivacyPolicy from "@/components/ModalPrivacyPolicy.vue";
  import ModalRefundPolicy from "@/components/ModalRefundPolicy.vue";
  import ModalTermsPolicy from "@/components/ModalTermsPolicy.vue";

  export default {
    components: {ModalTermsPolicy, ModalRefundPolicy, ModalPrivacyPolicy},
    data() {
      return {
        privacyPolicy: false,
        privacyTerms: false,
        privacyRefund: false,
      }
    },
    methods: {
      openInModal(modal) {
        if (modal === 'policy') {
          this.privacyPolicy = !this.privacyPolicy
        } else if (modal === 'terms') {
          this.privacyTerms = !this.privacyTerms
        } else if (modal === 'refund') {
          this.privacyRefund = !this.privacyRefund
        }
      },
    }
  }
</script>

<style scoped>
  .footer-text {
    margin-left: 6%;
  }

  .footer-text span {
    cursor: pointer;
  }

  @media screen and (max-width: 1400px){
    .footer-text {
      margin: 0;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 720px){
    .footer-text p {
      flex-direction: column;
      align-items: normal!important;
    }
  }
</style>