<template>
  <Header class="light" theme="light" />
  <div class="container">
    <div class="user-navigations">
      <div :class="'user-nav-btn' + [this.active === 1 ? ' btn-active' : '']" @click="checkBlock(1)">
        <img src="../assets/img/icons/user.png" alt="user">
        <span>Профиль</span>
      </div>

      <div :class="'user-nav-btn' + [this.active === 2 ? ' btn-active' : '']" @click="checkBlock(2)">
        <img src="../assets/img/icons/star.png" alt="star">
        <span>Избранное</span>
      </div>

      <div :class="'user-nav-btn' + [this.active === 3 ? ' btn-active' : '']" @click="checkBlock(3)">
        <img src="../assets/img/icons/money.png" alt="user">
        <span>Внести Депозит</span>
      </div>

      <div :class="'user-nav-btn' + [this.active === 4 ? ' btn-active' : '']" @click="checkBlock(4)">
        <img src="../assets/img/icons/bid.png" alt="user">
        <span>Мои ставки</span>
      </div>

      <div :class="'user-nav-btn' + [this.active === 5 ? ' btn-active' : '']" @click="checkBlock(5)">
        <img src="../assets/img/icons/car.png" alt="user">
        <span>Мои авто</span>
      </div>
    </div>
  </div>
  <UserDetail v-if="active === 1" :click="checkBlock" />
  <MyFavoriteCars v-if="active === 2" />
  <Deposit v-if="active === 3" :click="checkBlock" />
  <MyBids v-if="active === 4" />
  <MyAuto v-if="active === 5" />
  <AllCardInfo v-if="active === 6" :click="checkBlock"  />
  <BankInfo v-if="active === 7" />
  <MBank v-if="active === 8" />
  <HistoryDeposit v-if="active === 9" />
  <Contacts />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Contacts from "@/components/Contacts.vue";
import UserDetail from "@/components/UserDetail.vue";
import Deposit from "@/components/Deposit.vue";
import AllCardInfo from "@/components/AllCardInfo.vue";
import BankInfo from "@/components/BankInfo.vue";
import MBank from "@/components/MBank.vue";
import HistoryDeposit from "@/components/HIstoryDeposit.vue";
import MyFavoriteCars from "@/components/MyFavoriteCars.vue";
import MyBids from "@/components/MyBids.vue";
import MyAuto from "@/components/MyAuto.vue";
import {useHead} from "@vueuse/head";

export default {
  setup() {
    useHead({
      title: 'Профиль - AutoCanada',
    })
  },
  data() {
    return {
      active: 1,
    }
  },
  components: {
    Header, Footer, Contacts, UserDetail, Deposit, AllCardInfo, BankInfo, MBank, HistoryDeposit, MyFavoriteCars,
    MyBids, MyAuto
  },
  methods: {
    checkBlock(value) {
      this.active = value
    },
  }
}
</script>

<style scoped>
  .user-navigations {
    display: flex;
    gap: 12px;
    margin: 70px auto 55px;
    overflow: auto;
    max-width: 1210px;
    padding-bottom: 15px;
  }

  .user-navigations::-webkit-scrollbar {
    height: 7px;
    background: #D9D9D9;
    border-radius: 3px;
  }

  .user-navigations::-webkit-scrollbar-track {
    -webkit-border-radius: 3px;
  }

  .user-navigations::-webkit-scrollbar-thumb {
    background: #A02C12;
    border-radius: 3px;
  }

  .user-nav-btn {
    cursor: pointer;
    background: #1E1E1E;
    border-radius: 11px;
    color: #FFFFFF;
    width: 232px;
    min-width: 220px;
    height: 106px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-nav-btn img {
    width: 50px;
    height: 50px;
  }

  .user-nav-btn span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-left: 10px;
  }

  .user-nav-btn.btn-active {
    background: #A02C12;
  }

  @media screen and (max-width: 1280px){
      .user-nav-btn {
        min-width: 190px;
        height: 96px;
      }
  }

  @media screen and (max-width: 576px){
    .user-navigations {
      margin-right: -12px;
      margin-left: -12px;
    }
  }
</style>